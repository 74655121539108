/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A string that cannot be passed as an empty value */
  CountryCode: { input: string; output: string; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string; }
  /** Hex color code string */
  HexColor: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: unknown; output: unknown; }
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: { input: string; output: string; }
  /** A string that cannot be passed as an empty value */
  NonEmptyString: { input: string; output: string; }
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: number; output: number; }
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: number; output: number; }
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: number; output: number; }
  /** Semantic version string */
  SemanticVersion: { input: string; output: string; }
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: number; output: number; }
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: string; output: string; }
  /** UUID v4 */
  UUID: { input: string; output: string; }
  /** Represents NULL values */
  Void: { input: null; output: null; }
};

/** Status of the apple music presave */
export const AppleMusicPresaveStatus = {
  Completed: 'COMPLETED',
  Pending: 'PENDING'
} as const;

export type AppleMusicPresaveStatus = typeof AppleMusicPresaveStatus[keyof typeof AppleMusicPresaveStatus];
/** Role of an artist admin */
export const ArtistAdminRole = {
  Admin: 'ADMIN',
  Artist: 'ARTIST'
} as const;

export type ArtistAdminRole = typeof ArtistAdminRole[keyof typeof ArtistAdminRole];
/** Type of artist membership receipt */
export const ArtistMembershipReceiptTypenames = {
  ArtistMembershipPlayStreamReceipt: 'ArtistMembershipPlayStreamReceipt',
  ArtistMembershipPresaveReceipt: 'ArtistMembershipPresaveReceipt',
  ArtistMembershipUnknownReceipt: 'ArtistMembershipUnknownReceipt'
} as const;

export type ArtistMembershipReceiptTypenames = typeof ArtistMembershipReceiptTypenames[keyof typeof ArtistMembershipReceiptTypenames];
/** Type of artist phone number to be assigned */
export const ArtistPhoneNumberType = {
  Local: 'LOCAL',
  TollFree: 'TOLL_FREE'
} as const;

export type ArtistPhoneNumberType = typeof ArtistPhoneNumberType[keyof typeof ArtistPhoneNumberType];
/** Artist socials input */
export type ArtistSocialsInput = {
  /** Custom website URL */
  customWebsiteUrl?: InputMaybe<Scalars['URL']['input']>;
  /** Instagram handle */
  instagramHandle?: InputMaybe<Scalars['String']['input']>;
  /** Spotify profile URL */
  spotifyUrl?: InputMaybe<Scalars['URL']['input']>;
  /** Tiktok handle */
  tiktokHandle?: InputMaybe<Scalars['String']['input']>;
};

/** Status of an artist */
export const ArtistStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Test: 'TEST'
} as const;

export type ArtistStatus = typeof ArtistStatus[keyof typeof ArtistStatus];
/** Details of the splits to be assigned */
export type AssignSplitDetailsInput = {
  /** Payee email to pay out this split */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier of the split. Expected to be a just-created random UUID */
  id: Scalars['UUID']['input'];
  /** Name of the payee */
  name: Scalars['String']['input'];
  /** Payee ID to pay out this split. Required if emailAddress is not provided */
  payeeId?: InputMaybe<Scalars['String']['input']>;
  /** Amount of the split in basis points */
  percentBps: Scalars['Int']['input'];
  /** Role of the payee */
  role: Scalars['String']['input'];
};

/** Multi-part part information */
export type CompletedMultipartUploadPart = {
  /** Opaque identifier of the part data */
  etag: Scalars['String']['input'];
  /** Individual part number identifier */
  partNumber: Scalars['Int']['input'];
};

/** Input type for connecting Spotify account */
export type ConnectSpotifyInput = {
  /** Spotify authorization code */
  code: Scalars['String']['input'];
  /** Spotify redirect URI */
  redirectUri: Scalars['String']['input'];
};

/** Update info about a given device */
export type DevicePlatformInfoInput = {
  /** App build */
  appBuild: Scalars['Int']['input'];
  /** Version identifier */
  appVersion: Scalars['String']['input'];
  /** Device type identifier */
  deviceType: Scalars['String']['input'];
  /** Platform identifier */
  platform: Scalars['String']['input'];
  /** Device timezone identifier */
  timezone: Scalars['String']['input'];
};

/** Type of external account, either card or bank account */
export const ExternalAccountType = {
  BankAccount: 'BANK_ACCOUNT',
  Card: 'CARD'
} as const;

export type ExternalAccountType = typeof ExternalAccountType[keyof typeof ExternalAccountType];
/** Feature type names */
export const FeatureTypename = {
  ChatRead: 'ChatRead',
  ChatSendAudioAttachments: 'ChatSendAudioAttachments',
  ChatSendImageAttachments: 'ChatSendImageAttachments',
  ChatSendVideoAttachments: 'ChatSendVideoAttachments',
  ChatWrite: 'ChatWrite',
  FreeScheduledEvent: 'FreeScheduledEvent',
  FreeVaultContent: 'FreeVaultContent',
  PaidScheduledEvent: 'PaidScheduledEvent',
  PaidVaultContent: 'PaidVaultContent',
  TrackCommentsRead: 'TrackCommentsRead',
  TrackCommentsWrite: 'TrackCommentsWrite',
  UnknownFeature: 'UnknownFeature',
  UserReferrals: 'UserReferrals'
} as const;

export type FeatureTypename = typeof FeatureTypename[keyof typeof FeatureTypename];
export type GifAttachmentInput = {
  /** aspect ratio of the gif. */
  aspectRatio: Scalars['Float']['input'];
  /** Unique identifier of the gif. */
  id: Scalars['String']['input'];
  /** title of the gif. */
  title: Scalars['String']['input'];
  /** url of the gif. */
  url: Scalars['String']['input'];
};

/** Possible status of a link */
export const LinkStatus = {
  Active: 'ACTIVE',
  Redirect: 'REDIRECT',
  Reserved: 'RESERVED',
  Unknown: 'UNKNOWN'
} as const;

export type LinkStatus = typeof LinkStatus[keyof typeof LinkStatus];
/** Type of a link */
export const LinkType = {
  Artist: 'ARTIST',
  Username: 'USERNAME'
} as const;

export type LinkType = typeof LinkType[keyof typeof LinkType];
/** Type of media entity, either Images or Audio */
export const MediaType = {
  Audio: 'AUDIO',
  Image: 'IMAGE',
  Recording: 'RECORDING',
  Unknown: 'UNKNOWN',
  Video: 'VIDEO'
} as const;

export type MediaType = typeof MediaType[keyof typeof MediaType];
/** Type of media entity, either Images or Audio */
export const MediaTypeInput = {
  Audio: 'AUDIO',
  Image: 'IMAGE',
  Recording: 'RECORDING',
  Video: 'VIDEO'
} as const;

export type MediaTypeInput = typeof MediaTypeInput[keyof typeof MediaTypeInput];
/** Type of messsage reaction */
export const MessageReactionType = {
  Emoji: 'EMOJI',
  Fire: 'FIRE',
  Heart: 'HEART',
  Laugh: 'LAUGH',
  ThumbsUp: 'THUMBS_UP',
  Unknown: 'UNKNOWN'
} as const;

export type MessageReactionType = typeof MessageReactionType[keyof typeof MessageReactionType];
/** Type of messsage reaction to be created */
export const MessageReactionTypeInput = {
  Emoji: 'EMOJI',
  Fire: 'FIRE',
  Heart: 'HEART',
  ThumbsUp: 'THUMBS_UP'
} as const;

export type MessageReactionTypeInput = typeof MessageReactionTypeInput[keyof typeof MessageReactionTypeInput];
/** Source of the message input */
export const MessageSource = {
  ContentSection: 'CONTENT_SECTION',
  VaultChat: 'VAULT_CHAT'
} as const;

export type MessageSource = typeof MessageSource[keyof typeof MessageSource];
export type MutationAbortMultipartUploadInput = {
  /** Upload ID to use for each upload part of the final object */
  uploadId: Scalars['String']['input'];
  /** Upload key for the final upload object */
  uploadKey: Scalars['String']['input'];
};

export type MutationAddVaultContentInput = {
  /** Custom caption of the content */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** Duration of the track in seconds */
  duration?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  /** Type of feature to allow access to the content */
  featureAccess?: VaultContentAccessFeatureInput | '%future added value';
  /** Normalized peaks of the track */
  normalizedPeaks?: InputMaybe<Array<Scalars['NonNegativeInt']['input']>>;
  /** Optional notification message to send to subscribers */
  notificationMessage?: InputMaybe<Scalars['String']['input']>;
  /** Parent vault content ID */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Priority of the content in the vault */
  priority?: Scalars['NonNegativeInt']['input'];
  /** Custom title of the content */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Track media ID to add to the vault */
  trackMediaId: Scalars['UUID']['input'];
  /** Optional unique ID of the content */
  uniqueId?: InputMaybe<Scalars['String']['input']>;
  /** Vault ID to add content to */
  vaultId: Scalars['UUID']['input'];
};

export type MutationAppleMusicPresaveInput = {
  /** The Apple Music auth user token if user is not authenticated */
  appleMusicAuthUserToken?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the release campaign */
  releaseCampaignId: Scalars['UUID']['input'];
};

export type MutationAssignArtistAdminInput = {
  /** Artist handle */
  artistHandle: Scalars['String']['input'];
  /** Username or uuid of the user to assign as admin */
  newAdmin: Scalars['String']['input'];
  /** Role to assign to the user */
  role?: ArtistAdminRole | '%future added value';
};

/** Input for creating a group of splits */
export type MutationAssignSplitsInput = {
  /** Details of the splits */
  splitDetails: Array<AssignSplitDetailsInput>;
  /** ID of the VaultContent for split */
  vaultContentId: Scalars['UUID']['input'];
};

/** Information about the payee to be attached to authenticated user */
export type MutationAttachPayeeToUserInput = {
  /** Artist ID to attach to the payee, if authenticated user manages the artist */
  attachArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** Secret ID of the Payee to be attached */
  payeeSecretId: Scalars['UUID']['input'];
};

/** Input for banning a subscriber */
export type MutationBanSubscriberFromVaultInput = {
  /** The artist profile associated with the ban, if authenticated user is an admin */
  artistId: Scalars['UUID']['input'];
  /** The user id of the subscriber to ban */
  userId: Scalars['UUID']['input'];
};

export type MutationCancelSubscriptionInput = {
  /** vault subscriptionId */
  subscriptionId: Scalars['UUID']['input'];
};

export type MutationClaimArtistInvitationInput = {
  /** ID of the artist invitation */
  artistInvitationId: Scalars['UUID']['input'];
  /** ID of the profile image */
  profileImageId: Scalars['UUID']['input'];
};

export type MutationCompleteMultipartUploadInput = {
  /** Ordered list of uploaded multi-part parts to associate with the final object */
  parts: Array<CompletedMultipartUploadPart>;
  /** Upload ID to use for each upload part of the final object */
  uploadId: Scalars['String']['input'];
  /** Upload key for the final upload object */
  uploadKey: Scalars['String']['input'];
};

export type MutationConnectAppleMusicInput = {
  /** Override existing user account with Apple Music connection if already linked */
  overrideAccount?: InputMaybe<Scalars['Boolean']['input']>;
  /** Apple Music User Token */
  userToken: Scalars['String']['input'];
};

export type MutationCreateAnnouncementInput = {
  /** Artist identifier */
  artistId: Scalars['UUID']['input'];
  /** Content of the announcement */
  content: Scalars['String']['input'];
  /** Type of feature to allow access to the announcement */
  featureAccess?: ScheduledEventAccessFeatureInput | '%future added value';
  /** Time the announcement should be sent (null if sent right away) */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Input for creating an artist */
export type MutationCreateArtistInput = {
  /** Role of the artist admin */
  adminRole?: ArtistAdminRole | '%future added value';
  /** Identifier of uploaded media */
  avatarImageId?: InputMaybe<Scalars['UUID']['input']>;
  /** Email address of the artist */
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** Username of the existing user account */
  existingUsername?: InputMaybe<Scalars['String']['input']>;
  /** Unique handle for the artist, used for the artist's URL */
  handle: Scalars['String']['input'];
  /** Name of the artist */
  name?: Scalars['String']['input'];
  /** Type of phone number to assign to the artist */
  phoneNumber?: InputMaybe<ArtistPhoneNumberType | '%future added value'>;
  /** Area code for the phone number */
  phoneNumberAreaCode?: InputMaybe<Scalars['String']['input']>;
  /** Price of this artists vault. Default is $5 (500 cents) */
  price?: InputMaybe<Scalars['Int']['input']>;
  /** Whether to use the payee for the underlying user if it exists */
  shouldUseUserPayee?: InputMaybe<Scalars['Boolean']['input']>;
  /** Type of the vault */
  type: VaultType | '%future added value';
  /** Identifier of the user */
  userId?: InputMaybe<Scalars['UUID']['input']>;
  /** Phone number of the user */
  userPhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateArtistInvitationInput = {
  /** Email address of the artist to be invited */
  email: Scalars['EmailAddress']['input'];
  /** When the invitation expires. If not specified, it will expire in 7 days. */
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Unique artist handle */
  handle: Scalars['String']['input'];
  /** Name of the artist to be invited */
  name: Scalars['String']['input'];
};

export type MutationCreateMessageInput = {
  /** The id of the artist to send the message as, if the user is an admin of that artist */
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** The content of the message to create */
  content: Scalars['String']['input'];
  /** A GIF attachment to be sent with the message */
  gifAttachment?: InputMaybe<GifAttachmentInput>;
  /** An array of the ids of the uploaded attachments to be sent with the message */
  messageAttachments?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Id of the message channel to send the message to */
  messageChannelId: Scalars['UUID']['input'];
  /** An optional unique uuid generated by client-side to use for optimistic updates */
  optimisticId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the message to reply to */
  replyToId?: InputMaybe<Scalars['UUID']['input']>;
  /** Source of the message input */
  source?: InputMaybe<MessageSource | '%future added value'>;
  /** ID of the root message of the thread */
  threadRootId?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * An array of the ids of the vault content to be sent with the message
   * @deprecated Use vaultContentId instead of vaultContent
   */
  vaultContent?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The id of the vault content to be sent with the message */
  vaultContentId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateMessageReactionInput = {
  /** The id of the artist to send the reaction as, if the user is an admin of that artist */
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** The emoji keyword to use for the reaction */
  emojiKeyword?: InputMaybe<Scalars['String']['input']>;
  /** Id of the message to create a reaction too */
  messageId: Scalars['UUID']['input'];
  /** Type of reaction to create */
  reactionType: MessageReactionTypeInput | '%future added value';
};

export type MutationCreateMultipartUploadInput = {
  /** Artist ID to use for the upload, if authenticated user is an admin */
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** File name of media to be uploaded */
  fileName: Scalars['String']['input'];
  /** Media type to be uploaded */
  mediaType: MediaTypeInput | '%future added value';
  /** Number of parts to use for multi-part upload */
  totalPartsCount: Scalars['Int']['input'];
};

export type MutationCreateReleaseCampaignInput = {
  /** The announcement to send to users */
  announcement?: InputMaybe<ReleaseCampaignAnnouncementInput>;
  /** The Apple Music resource ID of the content */
  appleMusicResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The handle of the associated artist */
  artistHandle: Scalars['String']['input'];
  /** The type of content */
  contentType?: ReleaseCampaignContentType | '%future added value';
  /** Media ID of the cover image */
  coverImageMediaId?: InputMaybe<Scalars['UUID']['input']>;
  /** The description of the release campaign */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The external links associated with the release campaign */
  externalLinks: Array<ReleaseCampaignExternalLinkInput>;
  /** The ISRC of the content */
  isrc?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not presaves are enabled */
  presavesEnabled: Scalars['Boolean']['input'];
  /** The date and time when the release campaign content will be released */
  releaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The message to send to users upon release */
  releaseMessage?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to find the Apple Music link */
  shouldFindAppleMusicLink?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to find the Spotify link */
  shouldFindSpotifyLink?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Apple Music presave */
  showAppleMusicPresave?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Spotify presave */
  showSpotifyPresave?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Spotify presave link first */
  showSpotifyPresaveFirst?: InputMaybe<Scalars['Boolean']['input']>;
  /** The Spotify resouce ID of the content */
  spotifyResourceId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The message to send to users upon presave/stream
   * @deprecated Not used
   */
  thankYouMessage?: InputMaybe<Scalars['String']['input']>;
  /** The URL of the third party release image */
  thirdPartyReleaseImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** The title of the release campaign */
  title: Scalars['String']['input'];
  /** The UPC of the content */
  upc?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the associated vault content */
  vaultContentId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateSmsCampaignInput = {
  /** Whether or not the campaign is active */
  active?: Scalars['Boolean']['input'];
  /** Handle of the artist to create the campaign for */
  artistHandle: Scalars['String']['input'];
  /** Phone number of the artist to create the campaign for */
  artistPhoneNumber: Scalars['String']['input'];
  /** Message for campaign response texts */
  message?: InputMaybe<Scalars['String']['input']>;
};

/** Split created successfully */
export type MutationCreateSplitsInput = {
  /** Details of the splits */
  splitDetails: Array<SplitDetailsInputRef>;
  /** ID of the VaultContent for split */
  vaultContentId: Scalars['String']['input'];
};

export type MutationCreateSubscriptionPaymentIntentInput = {
  /** Referral code to apply to the subscription */
  referralCode?: InputMaybe<Scalars['String']['input']>;
  /** Vault id to subscribe to */
  vaultId: Scalars['UUID']['input'];
};

export type MutationCreateUserFeedbackInput = {
  /** User feedback text */
  text: Scalars['String']['input'];
  /** The id of the associated vault */
  vaultId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateVaultContentInput = {
  /** Custom caption of the content */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** Type of content to add */
  contentType: VaultContentType | '%future added value';
  /** Whether to enable users to download the content; currently only for tracks */
  downloadEnabled?: Scalars['Boolean']['input'];
  /** Duration of the track in seconds */
  duration?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  /** Type of feature to allow access to the content */
  featureAccess?: VaultContentAccessFeatureInput | '%future added value';
  /** Media ID to add to the vault */
  mediaId: Scalars['UUID']['input'];
  /** Normalized peaks of the track */
  normalizedPeaks?: InputMaybe<Array<Scalars['NonNegativeInt']['input']>>;
  /** Optional notification message to send to subscribers */
  notificationMessage?: InputMaybe<Scalars['String']['input']>;
  /** Parent vault content ID */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Priority of the content in the vault */
  priority?: Scalars['NonNegativeInt']['input'];
  /** Whether to send SMS notifications */
  shouldSendSms: Scalars['Boolean']['input'];
  /** Custom title of the content */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Optional unique ID of the content */
  uniqueId?: InputMaybe<Scalars['String']['input']>;
  /** Vault ID to add content to */
  vaultId: Scalars['UUID']['input'];
};

export type MutationCreateVaultFolderInput = {
  /** Type of feature to allow access to the content */
  featureAccess?: VaultContentAccessFeatureInput | '%future added value';
  /** Parent vault content ID */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Title of the folder */
  title: Scalars['String']['input'];
  /** Vault ID to create folder in */
  vaultId: Scalars['UUID']['input'];
};

export type MutationCustomizeVaultInput = {
  /** Accent color as hex */
  accentColor: Scalars['HexColor']['input'];
  /** Background color as hex */
  backgroundColor: Scalars['HexColor']['input'];
  /** ID of the profile image */
  logoImageId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the profile image */
  profileImageId?: InputMaybe<Scalars['UUID']['input']>;
  /** Remove the logo image */
  shouldRemoveLogo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Vault ID to theme */
  vaultId: Scalars['UUID']['input'];
};

export type MutationDeactivateReleaseCampaignInput = {
  /** The ID of the release campaign to deactivate */
  releaseCampaignId: Scalars['String']['input'];
};

export type MutationDeleteAnnouncementInput = {
  /** ID of the scheduled announcement */
  scheduledEventId: Scalars['UUID']['input'];
};

export type MutationDeleteMessageInput = {
  /** Id of the message to delete */
  messageId: Scalars['UUID']['input'];
};

export type MutationDeleteMessageReactionInput = {
  /** The id of the artist to remove the reaction as, if the user is an admin of that artist */
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  /** The emoji keyword to use for the reaction */
  emojiKeyword?: InputMaybe<Scalars['String']['input']>;
  /** Id of the message to delete reaction */
  messageId: Scalars['UUID']['input'];
  /** Type of reaction to delete */
  reactionType: MessageReactionType | '%future added value';
};

export type MutationDeleteReleaseCampaignInput = {
  /** The ID of the release campaign to delete */
  releaseCampaignId: Scalars['String']['input'];
};

export type MutationEditVaultContentInput = {
  /** Custom caption of the content */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** Whether to enable users to download the content; currently only for tracks */
  downloadEnabled?: Scalars['Boolean']['input'];
  /** Type of feature to allow access to the content */
  featureAccess?: InputMaybe<VaultContentAccessFeatureInput | '%future added value'>;
  /** Priority of the content in the vault */
  priority?: Scalars['NonNegativeInt']['input'];
  /** Custom title of the content */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Vault Content ID to edit content for */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationEditVaultContentSnippetInput = {
  /** End time of the snippet in seconds */
  endTime?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  /** Start time of the snippet in seconds */
  startTime: Scalars['NonNegativeInt']['input'];
  /** Vault Content ID to edit snippet for */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationHideAnnouncementInput = {
  /** ID of the announcement */
  scheduledEventId: Scalars['UUID']['input'];
};

export type MutationLinkAppleMusicInput = {
  /** Override existing user account with Apple Music connection if already linked */
  overrideAccount?: InputMaybe<Scalars['Boolean']['input']>;
  /** Apple Music User Token */
  userToken: Scalars['String']['input'];
};

export type MutationMoveContentsToVaultFolderInput = {
  /** Content IDs to move to folder */
  contentIds: Array<Scalars['UUID']['input']>;
  /** Folder ID to move content to (null to move to main vault level) */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Vault ID to for the content */
  vaultId: Scalars['UUID']['input'];
};

export type MutationNotifyPlayedContentInput = {
  /** Artist identifier */
  artistId: Scalars['UUID']['input'];
  /** Vault content identifier */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationPinVaultContentMessageInput = {
  /** Id of the message to pin */
  messageId: Scalars['UUID']['input'];
};

export type MutationPreviewMembershipCardImageUrlInput = {
  /** ID of the image media */
  backgroundImageMediaId: Scalars['UUID']['input'];
};

export type MutationReactivateReleaseCampaignInput = {
  /** The ID of the release campaign to reactivate */
  releaseCampaignId: Scalars['String']['input'];
};

/** Input for registering a mobile device token for push notifications */
export type MutationRegisterMobileDeviceTokenInput = {
  /** Firebase device token for mobile notifications */
  firebaseToken: Scalars['String']['input'];
  /** Indicates if the device is currently considered logged in */
  isLoggedIn: Scalars['Boolean']['input'];
  /** Device platform info */
  platformInfo: DevicePlatformInfoInput;
  /** Enable or disable push notification permissions */
  pushPermissionsEnabled: Scalars['Boolean']['input'];
};

export type MutationRemoveAllPinnedMessageInput = {
  /** Id of the message channel to remove unpin all */
  messageChannelId: Scalars['UUID']['input'];
};

export type MutationRemovePaymentMethodInput = {
  id: Scalars['String']['input'];
};

export type MutationRemovePinnedMessageInput = {
  /** Id of the message to remove as pinned */
  messageId: Scalars['UUID']['input'];
};

export type MutationRemovePinnedVaultContentMessageInput = {
  /** Id of the vault content */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationRemoveVaultContentInput = {
  /** Vault Content ID to remove content from */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationRemoveVaultContentsInput = {
  /** Folder IDs to remove */
  contentIds: Array<Scalars['UUID']['input']>;
  /** Vault ID to remove folder in */
  vaultId: Scalars['UUID']['input'];
};

export type MutationReportPlaySessionInput = {
  duration: Scalars['NonNegativeFloat']['input'];
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
  numTimesPaused: Scalars['NonNegativeInt']['input'];
  sessionId: Scalars['UUID']['input'];
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationReportPlayStreamReleaseCampaignInput = {
  /** Apple Music auth user token. Only required if user is not authenticated. */
  appleMusicAuthUserToken?: InputMaybe<Scalars['String']['input']>;
  /** Release campaign identifier */
  releaseCampaignId: Scalars['UUID']['input'];
  /** Spotify auth code. Only required if user is not authenticated. */
  spotifyAuthCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationResetDeviceTokenInput = {
  /** Firebase device token for push notifications */
  firebaseToken: Scalars['String']['input'];
};

/** Input for banning a subscriber from a chat from Retool */
export type MutationRetoolBanSubscriberInput = {
  /** The artist id banning the user */
  artistId: Scalars['UUID']['input'];
  /** The user id of the subscriber to ban */
  userId: Scalars['UUID']['input'];
};

export type MutationSeenFeatureInput = {
  /** The key of the feature which has been viewed */
  featureKey: FeatureTypename | '%future added value';
};

export type MutationSendGreetingsInput = {
  message: Scalars['String']['input'];
};

export type MutationSetCustomPreferencesInput = {
  /** The custom vault view type */
  vaultViewType: VaultViewType | '%future added value';
};

export type MutationSetOpenedPushNotificationTimeInput = {
  /** Time when the push notification was opened */
  clickedAt: Scalars['DateTime']['input'];
  /** Id of the pushNotification */
  pushNotificationId: Scalars['String']['input'];
};

export type MutationSetPresaveConfigInput = {
  /** The Apple Music resource ID to enable apple music presave */
  appleMusicResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The date and time when the presave campaign ends */
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The Spotify album URI to enable spotify presave */
  spotifyAlbumURI?: InputMaybe<Scalars['URL']['input']>;
  /** The date and time when the presave campaign starts */
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The ID of the vault content */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationSetSpotifyStateRedirectInput = {
  /** Spotify state redirect url */
  redirectUrl: Scalars['URL']['input'];
  /** Spotify state */
  state: Scalars['String']['input'];
};

export type MutationSetUserVaultContentPositionAsSeenInput = {
  /** Vault content ID to get position for */
  vaultContentId: Scalars['UUID']['input'];
};

export type MutationSetVaultContentOrderInput = {
  /** Content IDs in the order they should appear */
  contentIds: Array<Scalars['UUID']['input']>;
  /** Parent folder ID to set content order */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Vault ID to set content order */
  vaultId: Scalars['UUID']['input'];
};

export type MutationSetVaultWelcomeMessageInput = {
  /** Vault ID */
  vaultId: Scalars['UUID']['input'];
  /** Welcome message to set on the vault */
  welcomeMessage: Scalars['String']['input'];
  /** Welcome message to set on the vault when sending from personal number */
  welcomePersonalNumberMessage?: InputMaybe<Scalars['String']['input']>;
};

export type MutationShowAnnouncementInput = {
  /** ID of the announcement */
  scheduledEventId: Scalars['UUID']['input'];
};

export type MutationSpotifyPresaveInput = {
  /** The ID of the release campaign */
  releaseCampaignId: Scalars['UUID']['input'];
  /** The Spotify auth user token if user is not authenticated */
  spotifyAuthUserToken?: InputMaybe<Scalars['String']['input']>;
};

/** Input for subscribing to a vault for free */
export type MutationSubscribeToVaultFreeInput = {
  /** Invite code from user */
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  /** Shortcode for the SMS campaign response associated with the subscribe action */
  smsCampaignResponseShortcode?: InputMaybe<Scalars['String']['input']>;
  /** ReleaseCampaignId of the release campaign page that the user came from */
  sourceReleaseCampaignId?: InputMaybe<Scalars['String']['input']>;
  /** VaultContentId of the vault content page that the user came from */
  sourceVaultContentId?: InputMaybe<Scalars['String']['input']>;
  /** Vault ID to subscribe to */
  vaultId: Scalars['UUID']['input'];
};

/** Input for subscribing to a vault */
export type MutationSubscribeToVaultInput = {
  /** Invite code from user */
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  /** Shortcode for the SMS campaign response associated with the subscribe action */
  smsCampaignResponseShortcode?: InputMaybe<Scalars['String']['input']>;
  /** ReleaseCampaignId of the release campaign page that the user came from */
  sourceReleaseCampaignId?: InputMaybe<Scalars['String']['input']>;
  /** VaultContentId of the vault content page that the user came from */
  sourceVaultContentId?: InputMaybe<Scalars['String']['input']>;
  /** Stripe subscriptionId */
  subscriptionId: Scalars['String']['input'];
  /** Vault ID to subscribe to */
  vaultId: Scalars['UUID']['input'];
};

/** Input for banning a subscriber */
export type MutationUnbanSubscriberFromVaultInput = {
  /** The artist profile associated with the ban, if authenticated user is an admin */
  artistId: Scalars['UUID']['input'];
  /** The user id of the subscriber to ban */
  userId: Scalars['UUID']['input'];
};

export type MutationUpdateArtistProfileInput = {
  /** ID of the artist */
  artistId: Scalars['UUID']['input'];
  /** Profile description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the artist */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the profile image */
  profileImageId?: InputMaybe<Scalars['UUID']['input']>;
  /** Socials of the artist */
  socials?: InputMaybe<ArtistSocialsInput>;
};

export type MutationUpdateArtistUserSettingsInput = {
  /** The id of the artist */
  artistId: Scalars['UUID']['input'];
  /** Should receive push notifications */
  isPushNotificationEnabled: Scalars['Boolean']['input'];
  /** Should receive sms notifications */
  isSmsEnabled: Scalars['Boolean']['input'];
};

export type MutationUpdatePaymentMethodInput = {
  /** Current payment method */
  currentPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  /** New payment method */
  id: Scalars['String']['input'];
};

export type MutationUpdatePinnedMessageInput = {
  /** Id of the message to set as pinned */
  messageId: Scalars['UUID']['input'];
};

/** Input updating a user push notifications settings */
export type MutationUpdatePushNotificationsSettingsInput = {
  /** Should receive push notifications for unread messages */
  catchUpOnUnreadMessages?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new unplayed tracks */
  discoverNewUnplayedTracks?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for invites being redeemed */
  inviteRedeemed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new artist messages */
  newArtistMessage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new artists */
  newArtistOnVault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications for new artist tracks */
  newArtistTrack?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications for new subscribers */
  newSubscriber?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new subscription unlocks */
  newSubscriptionUnlock?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for new vault content */
  newVaultContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications */
  notificationsEnabled: Scalars['Boolean']['input'];
  /** Should receive push notifications for subscribed artist activity */
  subscribedArtistIsActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for mentions of you */
  userMentionedYou?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for reactions to your messages */
  userReactedToYourMessage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive push notifications for replies to your messages */
  userRepliedToYourMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateReleaseCampaignInput = {
  /** The announcement to send to users */
  announcement?: InputMaybe<ReleaseCampaignAnnouncementInput>;
  /** The Apple Music resource ID of the content */
  appleMusicResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The handle of the associated artist */
  artistHandle?: InputMaybe<Scalars['String']['input']>;
  /** The type of content */
  contentType: ReleaseCampaignContentType | '%future added value';
  /** Media ID of the cover image */
  coverImageMediaId?: InputMaybe<Scalars['UUID']['input']>;
  /** The description of the release campaign */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The external links associated with the release campaign */
  externalLinks: Array<ReleaseCampaignExternalLinkInput>;
  /** The ISRC of the content */
  isrc?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the release campaign to update */
  releaseCampaignId: Scalars['UUID']['input'];
  /** The date and time when the release campaign content will be released */
  releaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The message to send to users upon release */
  releaseMessage?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to find the Apple Music link */
  shouldFindAppleMusicLink?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to find the Spotify link */
  shouldFindSpotifyLink?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Apple Music presave */
  showAppleMusicPresave?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Spotify presave */
  showSpotifyPresave?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not to show the Spotify presave link first */
  showSpotifyPresaveFirst?: InputMaybe<Scalars['Boolean']['input']>;
  /** The Spotify resource ID of the content */
  spotifyResourceId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The message to send to users upon presave/stream
   * @deprecated Not used
   */
  thankYouMessage?: InputMaybe<Scalars['String']['input']>;
  /** The title of the release campaign */
  title: Scalars['String']['input'];
  /** The UPC of the content */
  upc?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the associated vault content */
  vaultContentId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationUpdateSmsCampaignInput = {
  /** Whether or not the campaign is active */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** SMS campaign ID */
  id: Scalars['String']['input'];
  /** Message for campaign response texts */
  message?: InputMaybe<Scalars['String']['input']>;
};

/** Input updating a user sms notifications settings */
export type MutationUpdateSmsNotificationsSettingsInput = {
  /** Should receive sms notifications for new artist tracks */
  newArtistTrack?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications for new subscribers */
  newSubscriber?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should receive sms notifications */
  notificationsEnabled: Scalars['Boolean']['input'];
};

/** Input updating a user upsell interstitials */
export type MutationUpdateUpsellInterstitialsInput = {
  /** The user used the chat for the first time */
  firstChat?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user logged in for the first time */
  firstLogin?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user shared a snippet for the first time */
  firstSnippetShare?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user listened to a track for the first time */
  firstTrack?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateUserFeedbackInput = {
  /** The id of the user feedback */
  id: Scalars['UUID']['input'];
  /** Admin notes on the feedback */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Status of the user feedback */
  status?: InputMaybe<UserFeedbackStatus | '%future added value'>;
};

/** Input updating a user's fields */
export type MutationUpdateUserInput = {
  /** The id of the new avatar (null to remove avatar) */
  newAvatarId?: InputMaybe<Scalars['String']['input']>;
  /** The new display name */
  newDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** The new email address (null to remove email address) */
  newEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  /** The new location place id (null to remove location) */
  newLocationPlaceId?: InputMaybe<Scalars['String']['input']>;
  /** The new location place input label */
  newLocationPlaceInputLabel?: InputMaybe<Scalars['String']['input']>;
  /** The new username */
  newUsername?: InputMaybe<Scalars['String']['input']>;
  /** The new zip code (null to remove zip code) */
  newZipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Input updating a user's fields */
export type MutationUpdateUserRemoveFieldsInput = {
  /** Should remove the avatar */
  shouldRemoveAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  /** Should remove the display name */
  shouldRemoveDisplayName?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateVaultFolderInput = {
  /** Type of feature to allow access to the content */
  featureAccess?: VaultContentAccessFeatureInput | '%future added value';
  /** Folder ID to update */
  folderId: Scalars['UUID']['input'];
  /** Title of the folder */
  title: Scalars['String']['input'];
  /** Vault ID to update folder in */
  vaultId: Scalars['UUID']['input'];
};

export type MutationUpsertUserViewContentInput = {
  /** Vault content identifier */
  vaultContentId: Scalars['UUID']['input'];
};

/** Status of the allowlist */
export const PhoneAllowlistStatus = {
  Allowed: 'ALLOWED',
  Pending: 'PENDING'
} as const;

export type PhoneAllowlistStatus = typeof PhoneAllowlistStatus[keyof typeof PhoneAllowlistStatus];
/** Platform type */
export const PlatformType = {
  Android: 'ANDROID',
  Ios: 'IOS',
  Web: 'WEB'
} as const;

export type PlatformType = typeof PlatformType[keyof typeof PlatformType];
/** Status of the presave configuration */
export const PresaveConfigurationStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Pending: 'PENDING'
} as const;

export type PresaveConfigurationStatus = typeof PresaveConfigurationStatus[keyof typeof PresaveConfigurationStatus];
/** Status of the presave */
export const PresaveStatus = {
  Cancelled: 'CANCELLED',
  Completed: 'COMPLETED',
  Failed: 'FAILED',
  Pending: 'PENDING',
  Scheduled: 'SCHEDULED'
} as const;

export type PresaveStatus = typeof PresaveStatus[keyof typeof PresaveStatus];
export type QueryArtistAnalyticsInput = {
  /** Optional date to get analytics for after */
  afterDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The id of the artist */
  artistId: Scalars['UUID']['input'];
  /** Optional date to get analytics for before */
  beforeDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryArtistByLinkInput = {
  /** Unique link of the artist */
  link: Scalars['String']['input'];
};

export type QueryArtistUserSettingsInput = {
  /** The id of the artist */
  artistId: Scalars['UUID']['input'];
};

export type QueryGetArtistReferralCodeByCodeInput = {
  /** The referral code */
  code: Scalars['String']['input'];
};

export type QueryGetArtistSubscriberBanStatusInput = {
  /** Artist identifier */
  artistId: Scalars['UUID']['input'];
  /** User identifier */
  userId: Scalars['UUID']['input'];
};

export type QueryGetBanStatusInput = {
  artistId: Scalars['UUID']['input'];
};

export type QueryGetTrackIdsToPopulateAudioQueueInput = {
  /** Parent vault content id to get children for */
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  /** Sort by option for the queue (Either ordered by time or position in folders) */
  sortBy?: VaultContentQueueSortBy | '%future added value';
  /** Vault id to get track ids for */
  vaultId: Scalars['UUID']['input'];
};

export type QueryMessageChannelByArtistHandleInput = {
  artistHandle: Scalars['String']['input'];
};

export type QueryMessagesByIdInput = {
  /** Ids of the messages to retrieve */
  messageIds: Array<Scalars['UUID']['input']>;
};

export type QueryOriginalAllVaultContentCsvInput = {
  /** Artist handles */
  artistHandles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryOriginalAllVaultContentInput = {
  /** Artist handle */
  artistHandle: Scalars['String']['input'];
};

export type QueryOriginalVaultContentInput = {
  /** Artist handle */
  artistHandle: Scalars['String']['input'];
  /** Content title */
  contentTitle: Scalars['String']['input'];
};

export type QueryPayeeStatusFromSecretInput = {
  /** Secret identifier of payee */
  secretId: Scalars['UUID']['input'];
};

export type QueryReleaseCampaignAppleMusicPresaveStatusInput = {
  /** The Apple Music auth user token */
  appleMusicAuthUserToken?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the release campaign */
  releaseCampaignId: Scalars['UUID']['input'];
};

export type QueryReleaseCampaignSpotifyPresaveStatusInput = {
  /** The ID of the release campaign */
  releaseCampaignId: Scalars['UUID']['input'];
  /** The Spotify auth user code */
  spotifyAuthUserCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRetoolMessageChannelByArtistIdInput = {
  artistId: Scalars['UUID']['input'];
};

export type QuerySplitDetailsInput = {
  /** ID of the VaultContent for split */
  vaultContentId: Scalars['String']['input'];
};

export type QuerySpotifyAuthStateRedirectUrlInput = {
  /** Spotify auth state */
  state: Scalars['String']['input'];
};

export type QuerySpotifyPresaveStatusInput = {
  /** The IDs of the presave configurations */
  presaveConfigIds: Array<Scalars['UUID']['input']>;
  /** The Spotify auth code if user is not authenticated with spotify linked */
  spotifyAuthCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryThirdPartyReleaseInformationInput = {
  /** Apple music resource ID */
  appleMusicResourceId?: InputMaybe<Scalars['String']['input']>;
  /** The type of resource */
  resourceType: ReleaseCampaignContentType | '%future added value';
  /** Spotify album URI */
  spotifyResourceId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserContentViewByContentIdInput = {
  /** Content ID to get user content view for */
  contentId: Scalars['UUID']['input'];
};

export type QueryVaultContentAnalyticsInput = {
  /** Optional date to get analytics for after */
  afterDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Optional artist to get track analytics for */
  artistId?: InputMaybe<Scalars['UUID']['input']>;
  /** Optional date to get analytics for before */
  beforeDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Limit the number of results returned */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Page to return */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryVaultContentChannelInput = {
  vaultContentId: Scalars['String']['input'];
};

export type QueryVaultContentsAreSeenInput = {
  /** List of content IDs to check if the user has seen  (max 25) */
  contentIds: Array<Scalars['UUID']['input']>;
};

export type QueryVaultSubscriberByUserIdForPublicInput = {
  /** User ID to get vault subscription for */
  userId: Scalars['UUID']['input'];
  /** Vault ID to get vault subscription for */
  vaultId: Scalars['UUID']['input'];
};

export type QueryVaultSubscriberByUserIdInput = {
  /** User ID to get vault subscription for */
  userId: Scalars['UUID']['input'];
  /** Vault ID to get vault subscription for */
  vaultId: Scalars['UUID']['input'];
};

export type QueryVaultSubscriptionInput = {
  subscriptionId: Scalars['UUID']['input'];
};

export type QueryVersionStatusInput = {
  /** Platform type */
  platform: PlatformType | '%future added value';
  /** Semantic version */
  version: Scalars['SemanticVersion']['input'];
};

/** Release Campaign Announcement Input */
export type ReleaseCampaignAnnouncementInput = {
  /** Announcement date */
  date: Scalars['DateTime']['input'];
  /** Announcement message */
  message: Scalars['String']['input'];
};

/** Type of content associated with a release campaign */
export const ReleaseCampaignContentType = {
  Album: 'ALBUM',
  Track: 'TRACK'
} as const;

export type ReleaseCampaignContentType = typeof ReleaseCampaignContentType[keyof typeof ReleaseCampaignContentType];
/** Release Campaign External Link Input */
export type ReleaseCampaignExternalLinkInput = {
  /** Call to action of the external link */
  cta?: InputMaybe<Scalars['String']['input']>;
  /** Whether the external link is enabled */
  enabled: Scalars['Boolean']['input'];
  /** Order of the external link within the release campaign */
  order: Scalars['Int']['input'];
  /** Platform of the external link */
  platform: ThirdPartyPlatform | '%future added value';
  /** URL of the external link */
  url: Scalars['String']['input'];
};

/** The current state of the release campaign */
export const ReleaseCampaignState = {
  Deleted: 'DELETED',
  Inactive: 'INACTIVE',
  Presave: 'PRESAVE',
  Streaming: 'STREAMING'
} as const;

export type ReleaseCampaignState = typeof ReleaseCampaignState[keyof typeof ReleaseCampaignState];
/** Status of the release campaign */
export const ReleaseCampaignStatus = {
  Active: 'ACTIVE',
  Deleted: 'DELETED',
  Inactive: 'INACTIVE'
} as const;

export type ReleaseCampaignStatus = typeof ReleaseCampaignStatus[keyof typeof ReleaseCampaignStatus];
/** Type of scheduled event access feature */
export const ScheduledEventAccessFeatureInput = {
  FreeEvent: 'FREE_EVENT',
  PaidEvent: 'PAID_EVENT'
} as const;

export type ScheduledEventAccessFeatureInput = typeof ScheduledEventAccessFeatureInput[keyof typeof ScheduledEventAccessFeatureInput];
/** Status of the scheduled event */
export const ScheduledEventStatus = {
  Active: 'ACTIVE',
  Deleted: 'DELETED',
  Hidden: 'HIDDEN',
  Inactive: 'INACTIVE'
} as const;

export type ScheduledEventStatus = typeof ScheduledEventStatus[keyof typeof ScheduledEventStatus];
/** Sort direction */
export const SortDirection = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
/** Details of the splits */
export type SplitDetailsInputRef = {
  /** Payee email to pay out this split */
  emailAddress: Scalars['String']['input'];
  /** Name of the payee */
  name: Scalars['String']['input'];
  /** Amount of the split in basis points */
  percentBps: Scalars['Int']['input'];
  /** Role of the payee */
  role: Scalars['String']['input'];
};

/** Status of the spotify presave */
export const SpotifyPresaveStatus = {
  Completed: 'COMPLETED',
  Pending: 'PENDING'
} as const;

export type SpotifyPresaveStatus = typeof SpotifyPresaveStatus[keyof typeof SpotifyPresaveStatus];
/** Spotify product status */
export const SpotifyProductStatus = {
  Free: 'free',
  Open: 'open',
  Premium: 'premium',
  Unknown: 'unknown'
} as const;

export type SpotifyProductStatus = typeof SpotifyProductStatus[keyof typeof SpotifyProductStatus];
/** Subscription tier level name */
export const SubscriptionTierLevel = {
  Free: 'FREE',
  Paid: 'PAID'
} as const;

export type SubscriptionTierLevel = typeof SubscriptionTierLevel[keyof typeof SubscriptionTierLevel];
/** Third party platform */
export const ThirdPartyPlatform = {
  Amazon: 'AMAZON',
  AppleMusic: 'APPLE_MUSIC',
  Bandcamp: 'BANDCAMP',
  Deezer: 'DEEZER',
  Discord: 'DISCORD',
  Other: 'OTHER',
  Soundcloud: 'SOUNDCLOUD',
  Spotify: 'SPOTIFY',
  Whatsapp: 'WHATSAPP',
  Youtube: 'YOUTUBE',
  YoutubeMusic: 'YOUTUBE_MUSIC'
} as const;

export type ThirdPartyPlatform = typeof ThirdPartyPlatform[keyof typeof ThirdPartyPlatform];
/** Base tier names of a vault */
export const TierTypename = {
  FreeTier: 'FreeTier',
  PaidTier: 'PaidTier'
} as const;

export type TierTypename = typeof TierTypename[keyof typeof TierTypename];
/** Status of the user feedback */
export const UserFeedbackStatus = {
  Pending: 'PENDING',
  Resolved: 'RESOLVED'
} as const;

export type UserFeedbackStatus = typeof UserFeedbackStatus[keyof typeof UserFeedbackStatus];
/** Type of vault content access feature */
export const VaultContentAccessFeatureInput = {
  FreeVaultContent: 'FREE_VAULT_CONTENT',
  PaidVaultContent: 'PAID_VAULT_CONTENT'
} as const;

export type VaultContentAccessFeatureInput = typeof VaultContentAccessFeatureInput[keyof typeof VaultContentAccessFeatureInput];
/** Sort by options for vault content queue */
export const VaultContentQueueSortBy = {
  FolderPosition: 'FolderPosition',
  SortTime: 'SortTime'
} as const;

export type VaultContentQueueSortBy = typeof VaultContentQueueSortBy[keyof typeof VaultContentQueueSortBy];
/** Status of the vault content */
export const VaultContentStatus = {
  Available: 'AVAILABLE',
  Deleted: 'DELETED',
  Hidden: 'HIDDEN',
  Pending: 'PENDING'
} as const;

export type VaultContentStatus = typeof VaultContentStatus[keyof typeof VaultContentStatus];
/** Type of content associated with a vault */
export const VaultContentType = {
  Folder: 'FOLDER',
  Image: 'IMAGE',
  Track: 'TRACK',
  Video: 'VIDEO'
} as const;

export type VaultContentType = typeof VaultContentType[keyof typeof VaultContentType];
/** Source type of the vault subscription */
export const VaultSubscriptionSourceType = {
  Import: 'IMPORT',
  PresaveCampaignPage: 'PRESAVE_CAMPAIGN_PAGE',
  StandardSignup: 'STANDARD_SIGNUP',
  StreamingCampaignPage: 'STREAMING_CAMPAIGN_PAGE',
  VaultContentPage: 'VAULT_CONTENT_PAGE'
} as const;

export type VaultSubscriptionSourceType = typeof VaultSubscriptionSourceType[keyof typeof VaultSubscriptionSourceType];
/** Status of the vault subscription */
export const VaultSubscriptionStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE'
} as const;

export type VaultSubscriptionStatus = typeof VaultSubscriptionStatus[keyof typeof VaultSubscriptionStatus];
/** Type of vault */
export const VaultType = {
  Freemium: 'FREEMIUM',
  FreeOnly: 'FREE_ONLY'
} as const;

export type VaultType = typeof VaultType[keyof typeof VaultType];
/** Type of vault view */
export const VaultViewType = {
  File: 'FILE',
  List: 'LIST'
} as const;

export type VaultViewType = typeof VaultViewType[keyof typeof VaultViewType];
export type NotifyPlayedContentMutationVariables = Exact<{
  input: MutationUpsertUserViewContentInput;
}>;


export type NotifyPlayedContentMutation = { upsertUserViewContent: { id: string } | null };

export type TrackContentByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type TrackContentByIdQuery = { vaultContentById: { __typename: 'VaultFolder', id: string } | { __typename: 'VaultImage', id: string } | { __typename: 'VaultTrack', id: string, createdAt: string, duration: number, title: string | null, linkValue: string, caption: string | null, commentMessageCount: number, isFullVersionAvailable: boolean, normalizedPeaks: Array<number>, parentVaultContentId: string | null, snippetVideo: { id: string, url: string } | null, freeTierSnippet: { id: string, startAt: number, endAt: number } | null, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null, membershipCardImage: { id: string, url: string } | null } | null, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null }, commentPinnedMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'TrackCommentRowFragment': TrackCommentRowFragment } }
    ) | null, commentCaptionMessage: (
      { id: string, content: string }
      & { ' $fragmentRefs'?: { 'TrackCommentRowFragment': TrackCommentRowFragment } }
    ) | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> } | { __typename: 'VaultVideo', id: string } | null, getSignedTrackContent: { __typename: 'NotFoundError' } | { __typename: 'QueryGetSignedTrackContentSuccess', data: string }, getSignedTrackSnippetContent: { __typename: 'NotFoundError' } | { __typename: 'QueryGetSignedTrackSnippetContentSuccess', data: string } | null };

export type ReportPlayStoppedAnalyticsMutationVariables = Exact<{
  input: MutationReportPlaySessionInput;
}>;


export type ReportPlayStoppedAnalyticsMutation = { reportPlaySession: string };

export type CreateAnnouncementMutationVariables = Exact<{
  input: MutationCreateAnnouncementInput;
}>;


export type CreateAnnouncementMutation = { createAnnouncement: { __typename: 'MutationCreateAnnouncementSuccess', data: { id: string } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'ValidationError', message: string } };

export type BubbleArtistFragment = { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null, mainVault: { id: string, contentCount: number, type: VaultType } } & { ' $fragmentName'?: 'BubbleArtistFragment' };

export type AttachPayeeStatusQueryVariables = Exact<{
  input: QueryPayeeStatusFromSecretInput;
}>;


export type AttachPayeeStatusQuery = { payeeStatusFromSecret: { __typename: 'NotFoundError', message: string } | { __typename: 'PayeeAlreadyAttachedError', message: string } | { __typename: 'QueryPayeeStatusFromSecretSuccess', data: { availableUserAccount: { email: string, name: string | null } | null, availableArtistAccounts: Array<{ artistId: string, email: string, name: string, profileImageUrl: string | null }> | null, lastSplit: { percentBps: number, content: { contentTitle: string | null, artistHandle: string, artistName: string } | null } | null } } };

export type AttachPayeeMutationVariables = Exact<{
  input: MutationAttachPayeeToUserInput;
}>;


export type AttachPayeeMutation = { attachPayeeToUser: { __typename: 'MutationAttachPayeeToUserSuccess', data: { id: string, email: string } } | { __typename: 'ValidationError', message: string } };

export type EventCreatedFragment = { id: string, initialReleaseImageUrl: string | null, currentState: ReleaseCampaignState, title: string, linkValue: string, coverImage: { id: string, url: string } | null, artist: { id: string, linkValue: string, profileImage: { id: string, url: string } | null } } & { ' $fragmentName'?: 'EventCreatedFragment' };

export type ReactorsByMessageIdQueryVariables = Exact<{
  messageId: Scalars['UUID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  reactionType?: InputMaybe<MessageReactionType>;
  emojiKeyword?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReactorsByMessageIdQuery = { reactorsByMessageId: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'QueryReactorsByMessageIdSuccess', data: { edges: Array<{ cursor: string, node: { actor: { __typename: 'MessageActorArtist', id: string, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string, dominantColor: string | null } | null } } | { __typename: 'MessageActorUser', id: string, user: { id: string, displayName: string | null, username: string | null, avatar: { id: string, url: string, dominantColor: string | null } | null } | { id: string, displayName: string | null, username: string | null, avatar: { id: string, url: string, dominantColor: string | null } | null } }, reactions: Array<{ id: string, type: MessageReactionType, emojiKeyword: string, createdAt: string }> } }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } } };

export type ReactCommentMutationVariables = Exact<{
  input: MutationCreateMessageReactionInput;
}>;


export type ReactCommentMutation = { createMessageReaction: { __typename: 'MutationCreateMessageReactionSuccess', data: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type RemoveReactCommentMutationVariables = Exact<{
  input: MutationDeleteMessageReactionInput;
}>;


export type RemoveReactCommentMutation = { deleteMessageReaction: { __typename: 'MutationDeleteMessageReactionSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type AddCommentMutationVariables = Exact<{
  input: MutationCreateMessageInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type AddCommentMutation = { createMessage: { __typename: 'MutationCreateMessageSuccess', data: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'TrackCommentRowFragment': TrackCommentRowFragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'RateLimitError', message: string } | { __typename: 'ValidationError', message: string } };

export type TrackCommentsQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TrackCommentsQuery = { vaultContentComments: { edges: Array<{ node: (
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'TrackCommentRowFragment': TrackCommentRowFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type DeleteCommentMutationVariables = Exact<{
  input: MutationDeleteMessageInput;
}>;


export type DeleteCommentMutation = { deleteMessage: { __typename: 'MutationDeleteMessageSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type GetSubscriberBanStatusQueryVariables = Exact<{
  userId: Scalars['UUID']['input'];
  artistId: Scalars['UUID']['input'];
}>;


export type GetSubscriberBanStatusQuery = { getArtistSubscriberBanStatus: boolean };

export type BanSubscriberMutationVariables = Exact<{
  input: MutationBanSubscriberFromVaultInput;
}>;


export type BanSubscriberMutation = { banSubscriberFromVault: boolean };

export type UnbanSubscriberMutationVariables = Exact<{
  input: MutationUnbanSubscriberFromVaultInput;
}>;


export type UnbanSubscriberMutation = { unbanSubscriberFromVault: boolean };

export type GetUserInformationQueryVariables = Exact<{
  input: QueryVaultSubscriberByUserIdInput;
}>;


export type GetUserInformationQuery = { vaultSubscriberByUserId: { __typename: 'NotAuthorizedError' } | { __typename: 'QueryVaultSubscriberByUserIdSuccess', data: { id: string, phone: string | null, email: string | null, vaultSubscriptionSourceText: string | null, vaultSubscriptionSourceType: VaultSubscriptionSourceType | null, createdAt: string, userLocation: { country: string | null, city: string | null, region: string | null } | null, artistMembership: { receipts: number } | null } } | null };

export type GetExposedUserInfoQueryVariables = Exact<{
  input: QueryVaultSubscriberByUserIdForPublicInput;
}>;


export type GetExposedUserInfoQuery = { vaultSubscriberByUserIdForPublic: { __typename: 'NotAuthorizedError' } | { __typename: 'QueryVaultSubscriberByUserIdForPublicSuccess', data: { createdAt: string } } | null };

export type ThirdPartyReleaseInformationQueryVariables = Exact<{
  input: QueryThirdPartyReleaseInformationInput;
}>;


export type ThirdPartyReleaseInformationQuery = { thirdPartyReleaseInformation: { __typename: 'QueryThirdPartyReleaseInformationSuccess', data: { releaseImageUrl: string | null, releaseTitle: string, releaseArtistName: string | null, urls: Array<{ platform: ThirdPartyPlatform, url: string }> } } | { __typename: 'ValidationError', message: string } };

export type ClaimReceiptViewFragment = { id: string, title: string, linkValue: string, currentState: ReleaseCampaignState, spotifyResourceId: string | null, receiptCount: number, appleMusicResourceId: string | null, contentType: ReleaseCampaignContentType, initialReleaseImageUrl: string | null, coverImage: { id: string, url: string } | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null }, externalLinks: Array<{ id: string, url: string, platform: ThirdPartyPlatform }> } & { ' $fragmentName'?: 'ClaimReceiptViewFragment' };

type MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment = { __typename: 'ArtistMembershipPlayStreamReceipt', id: string, createdAt: string, serialNumber: number, membership: { createdAt: string, points: number, serialNumber: number, artist: { id: string, name: string, linkValue: string, membershipCardImage: { id: string, url: string } | null } }, user: { id: string, displayName: string | null, username: string | null } | { id: string, displayName: string | null, username: string | null } } & { ' $fragmentName'?: 'MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment' };

type MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment = { __typename: 'ArtistMembershipPresaveReceipt', id: string, createdAt: string, serialNumber: number, membership: { createdAt: string, points: number, serialNumber: number, artist: { id: string, name: string, linkValue: string, membershipCardImage: { id: string, url: string } | null } }, user: { id: string, displayName: string | null, username: string | null } | { id: string, displayName: string | null, username: string | null } } & { ' $fragmentName'?: 'MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment' };

export type MembershipReceiptFragment = MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment | MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment;

export type ReleaseCampaignReportPlayStreamMutationVariables = Exact<{
  input: MutationReportPlayStreamReleaseCampaignInput;
}>;


export type ReleaseCampaignReportPlayStreamMutation = { reportPlayStreamReleaseCampaign: { __typename: 'MutationReportPlayStreamReleaseCampaignSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment': MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment } }
    ) | (
      { id: string }
      & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment': MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | null };

export type MusicCampaignViewFragment = { id: string, linkValue: string, status: ReleaseCampaignStatus, title: string, currentState: ReleaseCampaignState, initialReleaseImageUrl: string | null, description: string | null, showAppleMusicPresave: boolean, showSpotifyPresave: boolean, releaseDate: string | null, externalLinks: Array<{ id: string, cta: string | null, enabled: boolean, platform: ThirdPartyPlatform, url: string }>, coverImage: { id: string, url: string } | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null } } & { ' $fragmentName'?: 'MusicCampaignViewFragment' };

export type PresaveSpotifyInfoFragment = { id: string, presaveConfiguration: { id: string, spotifyAlbumURI: string | null, status: PresaveConfigurationStatus } | null } & { ' $fragmentName'?: 'PresaveSpotifyInfoFragment' };

export type SpotifyPresaveStatusQueryVariables = Exact<{
  input: QuerySpotifyPresaveStatusInput;
}>;


export type SpotifyPresaveStatusQuery = { spotifyPresaveStatus: { __typename: 'NotFoundError', message: string } | { __typename: 'QuerySpotifyPresaveStatusSuccess', data: Array<{ presaveConfigId: string }> } };

export type PresaveSpotifyMutationVariables = Exact<{
  presaveConfigurationId: Scalars['UUID']['input'];
  spotifyAuthCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type PresaveSpotifyMutation = { spotifyPresaveVaultContent: { __typename: 'MutationSpotifyPresaveVaultContentSuccess' } | { __typename: 'NotFoundError', message: string } };

export type GetCampaignAppleMusicPresaveStatusQueryVariables = Exact<{
  input: QueryReleaseCampaignAppleMusicPresaveStatusInput;
}>;


export type GetCampaignAppleMusicPresaveStatusQuery = { releaseCampaignAppleMusicPresaveStatus: { id: string, status: PresaveStatus } | null };

export type AppleMusicPresaveMutationVariables = Exact<{
  input: MutationAppleMusicPresaveInput;
}>;


export type AppleMusicPresaveMutation = { appleMusicPresave: { __typename: 'MutationAppleMusicPresaveSuccess' } | { __typename: 'NotFoundError', message: string } };

export type GetCampaignSpotifyPresaveStatusQueryVariables = Exact<{
  input: QueryReleaseCampaignSpotifyPresaveStatusInput;
}>;


export type GetCampaignSpotifyPresaveStatusQuery = { releaseCampaignSpotifyPresaveStatus: { id: string, status: PresaveStatus } | null };

export type SpotifyPresaveMutationVariables = Exact<{
  input: MutationSpotifyPresaveInput;
}>;


export type SpotifyPresaveMutation = { spotifyPresave: { __typename: 'MutationSpotifyPresaveSuccess' } | { __typename: 'NotFoundError', message: string } };

export type CreateReleaseCampaignMutationVariables = Exact<{
  input: MutationCreateReleaseCampaignInput;
}>;


export type CreateReleaseCampaignMutation = { createReleaseCampaign: { __typename: 'MutationCreateReleaseCampaignSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'EventCreatedFragment': EventCreatedFragment } }
    ) } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type UpdateReleaseCampaignMutationVariables = Exact<{
  input: MutationUpdateReleaseCampaignInput;
}>;


export type UpdateReleaseCampaignMutation = { updateReleaseCampaign: { __typename: 'MutationUpdateReleaseCampaignSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type DeleteReleaseCampaignMutationVariables = Exact<{
  input: MutationDeleteReleaseCampaignInput;
}>;


export type DeleteReleaseCampaignMutation = { deleteReleaseCampaign: { __typename: 'MutationDeleteReleaseCampaignSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } };

export type DeactivateReleaseCampaignMutationVariables = Exact<{
  input: MutationDeactivateReleaseCampaignInput;
}>;


export type DeactivateReleaseCampaignMutation = { deactivateReleaseCampaign: { __typename: 'MutationDeactivateReleaseCampaignSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } };

export type ReactivateReleaseCampaignMutationVariables = Exact<{
  input: MutationReactivateReleaseCampaignInput;
}>;


export type ReactivateReleaseCampaignMutation = { reactivateReleaseCampaign: { __typename: 'MutationReactivateReleaseCampaignSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } };

export type TrackCommentThreadPreviewFragment = { id: string, createdAt: string, updatedAt: string, content: string, source: MessageSource, activeSubscriptionTier: TierTypename | null, threadRootId: string | null, threadMessagesCount: number, asArtist: { __typename: 'Artist', id: string, linkValue: string, createdAt: string, name: string, profileImage: { id: string, url: string } | null } | null, user: { __typename: 'PrivateUser', id: string, createdAt: string, displayName: string | null, username: string | null, avatar: { id: string, url: string } | null } | { __typename: 'PublicUser', id: string, createdAt: string, displayName: string | null, username: string | null, avatar: { id: string, url: string } | null }, reactionsSummary: Array<{ type: MessageReactionType, count: number, emojiKeyword: string }>, replyTo: { ' $fragmentRefs'?: { 'ReplyToMessageFragment': ReplyToMessageFragment } } | null, artistReactions: Array<{ id: string, type: MessageReactionType, emojiKeyword: string }>, myReactionsInfo: Array<{ id: string, type: MessageReactionType, emojiKeyword: string }> } & { ' $fragmentName'?: 'TrackCommentThreadPreviewFragment' };

export type TrackCommentRowFragment = { id: string, content: string, source: MessageSource, createdAt: string, updatedAt: string, activeSubscriptionTier: TierTypename | null, threadRootId: string | null, threadMessagesCount: number, reactionsSummary: Array<{ type: MessageReactionType, emojiKeyword: string, count: number }>, artistReactions: Array<{ id: string, type: MessageReactionType, emojiKeyword: string }>, myReactionsInfo: Array<{ id: string, type: MessageReactionType, emojiKeyword: string }>, asArtist: { __typename: 'Artist', id: string, linkValue: string, createdAt: string, name: string, profileImage: { id: string, url: string, dominantColor: string | null } | null } | null, user: { __typename: 'PrivateUser', id: string, createdAt: string, displayName: string | null, username: string | null, avatar: { id: string, url: string, dominantColor: string | null } | null } | { __typename: 'PublicUser', id: string, createdAt: string, displayName: string | null, username: string | null, avatar: { id: string, url: string, dominantColor: string | null } | null }, replyTo: { ' $fragmentRefs'?: { 'ReplyToMessageFragment': ReplyToMessageFragment } } | null, threadMessagesPreview: Array<{ ' $fragmentRefs'?: { 'TrackCommentThreadPreviewFragment': TrackCommentThreadPreviewFragment } }> } & { ' $fragmentName'?: 'TrackCommentRowFragment' };

export type MessagesByIdQueryVariables = Exact<{
  input: QueryMessagesByIdInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type MessagesByIdQuery = { messagesById: Array<(
    { id: string, updatedAt: string }
    & { ' $fragmentRefs'?: { 'TrackCommentRowFragment': TrackCommentRowFragment } }
  )> };

export type ArtistLayoutFragment = { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null, membershipCardImage: { id: string, url: string } | null, mainVault: { id: string, price: number | null, messageChannelId: string, type: VaultType, contentCount: number, isUserArtistAdmin: boolean, activeSubscription: (
      { id: string, status: VaultSubscriptionStatus, currentPeriodEnd: string | null, stripeSubscriptionId: string | null }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string } | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } } & { ' $fragmentName'?: 'ArtistLayoutFragment' };

export type MessageChannelLayoutInfoFragment = { id: string, vault: { id: string, contentCount: number, artist: { id: string, name: string } | null } | null } & { ' $fragmentName'?: 'MessageChannelLayoutInfoFragment' };

export type VaultTrackIdsQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type VaultTrackIdsQuery = { vaultFromId: { id: string, trackIds: Array<string> } | null };

export type GetTrackIdsToPopulateQueueQueryVariables = Exact<{
  input: QueryGetTrackIdsToPopulateAudioQueueInput;
}>;


export type GetTrackIdsToPopulateQueueQuery = { getTrackIdsToPopulateAudioQueue: Array<string> };

export type GetWeeklySubscriberDataQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type GetWeeklySubscriberDataQuery = { weeklyNewSubscribers: { newSubscribersPerWeek: Array<{ newSubscriberCount: number, weekStart: string }> }, mySubscribersSummary: { activeSubscriptionsCount: number } };

export type EventItemFragment = { __typename: 'ReleaseCampaign', id: string, title: string, releaseDate: string | null, createdAt: string, linkValue: string, status: ReleaseCampaignStatus, currentState: ReleaseCampaignState, initialReleaseImageUrl: string | null, receiptCount: number, coverImage: { id: string, url: string } | null, artist: { id: string, isAuthUserAdmin: boolean, linkValue: string, profileImage: { id: string, url: string } | null } } & { ' $fragmentName'?: 'EventItemFragment' };

type CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment = { __typename: 'ArtistMembershipPlayStreamReceipt', id: string, createdAt: string, serialNumber: number, releaseCampaign: { id: string, title: string }, membership: { serialNumber: number }, user: { id: string, username: string | null, displayName: string | null } | { id: string, username: string | null, displayName: string | null }, artist: { id: string, name: string, linkValue: string } } & { ' $fragmentName'?: 'CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment' };

type CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment = { __typename: 'ArtistMembershipPresaveReceipt', id: string, createdAt: string, serialNumber: number, releaseCampaign: { id: string, title: string }, membership: { serialNumber: number }, user: { id: string, username: string | null, displayName: string | null } | { id: string, username: string | null, displayName: string | null }, artist: { id: string, name: string, linkValue: string } } & { ' $fragmentName'?: 'CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment' };

export type CampaignReceiptItemFragment = CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment | CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment;

export type MenuVaultSubscriptionFragment = { createdAt: string, id: string, updatedAt: string, status: VaultSubscriptionStatus, vault: { contentCount: number, id: string, artist: (
      { id: string, linkValue: string }
      & { ' $fragmentRefs'?: { 'ArtistVaultRowFragment': ArtistVaultRowFragment } }
    ) | null } } & { ' $fragmentName'?: 'MenuVaultSubscriptionFragment' };

export type GetPriorityActiveVaultSubscriptionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPriorityActiveVaultSubscriptionsQuery = { activeVaultSubscriptionsByPriority: { edges: Array<{ cursor: string, node: (
        { id: string }
        & { ' $fragmentRefs'?: { 'MenuVaultSubscriptionFragment': MenuVaultSubscriptionFragment;'MyVaultSubscriptionFragment': MyVaultSubscriptionFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type CustomizeVaultViewFragment = { id: string, accentColor: any, backgroundColor: any, artistId: string | null, artistProfile: { id: string, profileImage: { id: string, url: string, mediaType: MediaType } | null } | null, logoImage: { id: string, url: string, mediaType: MediaType } | null } & { ' $fragmentName'?: 'CustomizeVaultViewFragment' };

export type CustomizeVaultMutationVariables = Exact<{
  input: MutationCustomizeVaultInput;
}>;


export type CustomizeVaultMutation = { customizeVault: { __typename: 'MutationCustomizeVaultSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'CustomizeVaultViewFragment': CustomizeVaultViewFragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type VaultCustomizationQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type VaultCustomizationQuery = { vaultFromId: (
    { id: string }
    & { ' $fragmentRefs'?: { 'CustomizeVaultViewFragment': CustomizeVaultViewFragment } }
  ) | null };

export type DeleteMessageMutationVariables = Exact<{
  input: MutationDeleteMessageInput;
}>;


export type DeleteMessageMutation = { deleteMessage: { __typename: 'MutationDeleteMessageSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type PinMessageMutationVariables = Exact<{
  input: MutationUpdatePinnedMessageInput;
}>;


export type PinMessageMutation = { updatePinnedMessage: { __typename: 'MaximumPinnedReachedError', message: string } | { __typename: 'MutationUpdatePinnedMessageSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type RemovePinnedMessageMutationVariables = Exact<{
  input: MutationRemovePinnedMessageInput;
}>;


export type RemovePinnedMessageMutation = { removePinnedMessage: { __typename: 'MutationRemovePinnedMessageSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type CreateMessageReactionMutationVariables = Exact<{
  input: MutationCreateMessageReactionInput;
}>;


export type CreateMessageReactionMutation = { createMessageReaction: { __typename: 'MutationCreateMessageReactionSuccess' } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type DeleteMessageReactionMutationVariables = Exact<{
  input: MutationDeleteMessageReactionInput;
}>;


export type DeleteMessageReactionMutation = { deleteMessageReaction: { __typename: 'MutationDeleteMessageReactionSuccess' } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type VaultTrackByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
}>;


export type VaultTrackByIdQuery = { vaultContentById: { __typename: 'VaultFolder' } | { __typename: 'VaultImage' } | (
    { __typename: 'VaultTrack', id: string }
    & { ' $fragmentRefs'?: { 'TrackRowInfoFragment': TrackRowInfoFragment } }
  ) | { __typename: 'VaultVideo' } | null };

export type VaultMessageAttachmentFragment = { id: string, vaultContent: { id: string, title: string | null } | { id: string, title: string | null } | { id: string, title: string | null } | { id: string, title: string | null } | null } & { ' $fragmentName'?: 'VaultMessageAttachmentFragment' };

export type ReplyToMessageFragment = { id: string, createdAt: string, content: string, source: MessageSource, activeSubscriptionTier: TierTypename | null, asArtist: { id: string, linkValue: string, name: string, createdAt: string, profileImage: { id: string, url: string, dominantColor: string | null } | null } | null, user: { id: string, username: string | null, displayName: string | null, createdAt: string, avatar: { id: string, url: string, dominantColor: string | null } | null } | { id: string, username: string | null, displayName: string | null, createdAt: string, avatar: { id: string, url: string, dominantColor: string | null } | null }, messageAttachments: Array<{ id: string, media: { id: string, mediaType: MediaType, url: string } | null, gif: { id: string, url: string, title: string, aspectRatio: number } | null }>, vaultContent: Array<(
    { id: string }
    & { ' $fragmentRefs'?: { 'VaultMessageAttachmentFragment': VaultMessageAttachmentFragment } }
  )> } & { ' $fragmentName'?: 'ReplyToMessageFragment' };

export type MessageBubbleFragment = { id: string, source: MessageSource, content: string, createdAt: string, pinnedPriority: number | null, activeSubscriptionTier: TierTypename | null, replyToWasDeleted: boolean, asArtist: { id: string, linkValue: string, name: string, createdAt: string, profileImage: { id: string, url: string, dominantColor: string | null } | null } | null, user: { id: string, username: string | null, displayName: string | null, createdAt: string, avatar: { id: string, url: string, dominantColor: string | null } | null } | { id: string, username: string | null, displayName: string | null, createdAt: string, avatar: { id: string, url: string, dominantColor: string | null } | null }, artistReactions: Array<(
    { id: string, type: MessageReactionType, emojiKeyword: string }
    & { ' $fragmentRefs'?: { 'ArtistReactionsMessageReactionRowFragment': ArtistReactionsMessageReactionRowFragment } }
  )>, myReactions: Array<(
    { id: string, type: MessageReactionType, emojiKeyword: string }
    & { ' $fragmentRefs'?: { 'MyReactionsMessageReactionRowFragment': MyReactionsMessageReactionRowFragment } }
  )>, reactionsSummary: Array<{ ' $fragmentRefs'?: { 'MessageReactionRowFragment': MessageReactionRowFragment } }>, vaultContent: Array<(
    { id: string }
    & { ' $fragmentRefs'?: { 'VaultMessageAttachmentFragment': VaultMessageAttachmentFragment } }
  )>, messageAttachments: Array<{ id: string, media: { id: string, mediaType: MediaType, url: string } | null, gif: { id: string, url: string, title: string, aspectRatio: number } | null }>, replyTo: (
    { id: string, source: MessageSource }
    & { ' $fragmentRefs'?: { 'ReplyToMessageFragment': ReplyToMessageFragment } }
  ) | null } & { ' $fragmentName'?: 'MessageBubbleFragment' };

export type MessageSubscriptionBubbleFragment = { id: string, content: string, createdAt: string, source: MessageSource, activeSubscriptionTier: TierTypename | null, asArtist: { id: string, linkValue: string, name: string, profileImage: { id: string, url: string } | null } | null, user: { id: string, username: string | null, displayName: string | null, createdAt: string, avatar: { id: string, url: string } | null }, vaultContent: Array<{ id: string, vaultContent: { id: string, title: string | null } | null }>, messageAttachments: Array<{ id: string, media: { id: string, mediaType: MediaType, url: string } | null, gif: { id: string, url: string, title: string, aspectRatio: number } | null }>, replyTo: { id: string, content: string, source: MessageSource, createdAt: string, activeSubscriptionTier: TierTypename | null, asArtist: { id: string, linkValue: string, name: string, profileImage: { id: string, url: string } | null } | null, user: { id: string, username: string | null, displayName: string | null, createdAt: string, avatar: { id: string, url: string } | null }, messageAttachments: Array<{ id: string, media: { id: string, mediaType: MediaType, url: string } | null, gif: { id: string, url: string, title: string, aspectRatio: number } | null }>, vaultContent: Array<{ id: string, vaultContent: { id: string } | null }> } | null } & { ' $fragmentName'?: 'MessageSubscriptionBubbleFragment' };

export type MessageReactionRowFragment = { type: MessageReactionType, emojiKeyword: string, count: number } & { ' $fragmentName'?: 'MessageReactionRowFragment' };

export type MyReactionsMessageReactionRowFragment = { id: string, type: MessageReactionType, emojiKeyword: string } & { ' $fragmentName'?: 'MyReactionsMessageReactionRowFragment' };

export type ArtistReactionsMessageReactionRowFragment = { id: string, type: MessageReactionType, emojiKeyword: string } & { ' $fragmentName'?: 'ArtistReactionsMessageReactionRowFragment' };

export type SendMessageMutationVariables = Exact<{
  input: MutationCreateMessageInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type SendMessageMutation = { createMessage: { __typename: 'MutationCreateMessageSuccess', data: (
      { id: string, source: MessageSource }
      & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment } }
    ) } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'RateLimitError', message: string } | { __typename: 'ValidationError', message: string } };

export type GetBanStatusQueryVariables = Exact<{
  artistId: Scalars['UUID']['input'];
}>;


export type GetBanStatusQuery = { getBanStatus: boolean };

export type PinnedMessageFragment = { id: string, content: string, vaultContent: Array<(
    { id: string }
    & { ' $fragmentRefs'?: { 'VaultMessageAttachmentFragment': VaultMessageAttachmentFragment } }
  )> } & { ' $fragmentName'?: 'PinnedMessageFragment' };

export type EarningsRowFragment = { id: string | null, month: number, year: number, amount: number } & { ' $fragmentName'?: 'EarningsRowFragment' };

export type PaymentMethodRowFragment = { id: string, last4: string, brand: string, expMonth: number, expYear: number } & { ' $fragmentName'?: 'PaymentMethodRowFragment' };

export type GetCampaignForAudioPlayerQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetCampaignForAudioPlayerQuery = { releaseCampaignByIdOrSlug: { id: string, title: string, artist: { id: string, name: string, profileImage: { id: string, url: string } | null } } | null };

export type ArtistSubscribeHeaderInfoFragment = { id: string, name: string, profileImage: { id: string, url: string } | null, mainVault: { price: number | null } } & { ' $fragmentName'?: 'ArtistSubscribeHeaderInfoFragment' };

export type TrackFileInfoFragment = { __typename: 'VaultTrack', id: string, title: string | null, linkValue: string, createdAt: string, parentVaultContentId: string | null, normalizedPeaks: Array<number>, vaultId: string, duration: number, commentMessageCount: number, isFullVersionAvailable: boolean, vault: { id: string, isUserArtistAdmin: boolean, activeSubscription: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> } & { ' $fragmentName'?: 'TrackFileInfoFragment' };

export type TrackRowInfoFragment = (
  { __typename: 'VaultTrack', id: string, title: string | null, createdAt: string, normalizedPeaks: Array<number>, duration: number, parentVaultContentId: string | null, vaultId: string, isFullVersionAvailable: boolean, vault: { id: string, isUserArtistAdmin: boolean, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, freeTierSnippet: { id: string, duration: number } | null }
  & { ' $fragmentRefs'?: { 'WaveformTrackInfoFragment': WaveformTrackInfoFragment } }
) & { ' $fragmentName'?: 'TrackRowInfoFragment' };

type UserRow_PrivateUser_Fragment = { id: string, username: string | null, displayName: string | null, createdAt: string, avatar: { id: string, url: string, mediaType: MediaType, dominantColor: string | null } | null } & { ' $fragmentName'?: 'UserRow_PrivateUser_Fragment' };

type UserRow_PublicUser_Fragment = { id: string, username: string | null, displayName: string | null, createdAt: string, avatar: { id: string, url: string, mediaType: MediaType, dominantColor: string | null } | null } & { ' $fragmentName'?: 'UserRow_PublicUser_Fragment' };

export type UserRowFragment = UserRow_PrivateUser_Fragment | UserRow_PublicUser_Fragment;

export type ArtistVaultRowFragment = { id: string, name: string, profileImage: { id: string, url: string, dominantColor: string | null } | null } & { ' $fragmentName'?: 'ArtistVaultRowFragment' };

export type FolderItemFragment = { id: string, title: string | null, createdAt: string, parentVaultContentId: string | null, childrenVaultCount: number, nestedChildrenVaultContentCount: number, linkValue: string, vault: { id: string, activeSubscription: { id: string, createdAt: string } | null } } & { ' $fragmentName'?: 'FolderItemFragment' };

export type ImageItemFragment = { id: string, title: string | null, linkValue: string, createdAt: string, blurredMediaUrl: string | null, vaultId: string, parentVaultContentId: string | null, uploadedMedia: { id: string, mediaType: MediaType, url: string } | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, isUserArtistAdmin: boolean, artist: { id: string, profileImage: { id: string, url: string } | null } | null, activeSubscription: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'ImageItemFragment' };

export type TrackItemFragment = { id: string, title: string | null, linkValue: string, createdAt: string, vaultId: string, duration: number, commentMessageCount: number, parentVaultContentId: string | null, downloadEnabled: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, isUserArtistAdmin: boolean, artist: { id: string, name: string, profileImage: { id: string, url: string } | null, membershipCardImage: { id: string, url: string } | null } | null, activeSubscription: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'TrackItemFragment' };

type VaultItem_VaultFolder_Fragment = (
  { __typename: 'VaultFolder', id: string, parentVaultContentId: string | null, title: string | null }
  & { ' $fragmentRefs'?: { 'FolderItemFragment': FolderItemFragment } }
) & { ' $fragmentName'?: 'VaultItem_VaultFolder_Fragment' };

type VaultItem_VaultImage_Fragment = (
  { __typename: 'VaultImage', id: string, parentVaultContentId: string | null, title: string | null, uploadedMedia: { id: string, mediaType: MediaType, url: string } | null }
  & { ' $fragmentRefs'?: { 'ImageItemFragment': ImageItemFragment } }
) & { ' $fragmentName'?: 'VaultItem_VaultImage_Fragment' };

type VaultItem_VaultTrack_Fragment = (
  { __typename: 'VaultTrack', id: string, parentVaultContentId: string | null, title: string | null, vault: { id: string, artistProfile: { id: string, profileImage: { id: string, url: string } | null } | null } }
  & { ' $fragmentRefs'?: { 'TrackItemFragment': TrackItemFragment } }
) & { ' $fragmentName'?: 'VaultItem_VaultTrack_Fragment' };

type VaultItem_VaultVideo_Fragment = (
  { __typename: 'VaultVideo', id: string, parentVaultContentId: string | null, title: string | null, uploadedMedia: { id: string, mediaType: MediaType, url: string } | null }
  & { ' $fragmentRefs'?: { 'VideoItemFragment': VideoItemFragment } }
) & { ' $fragmentName'?: 'VaultItem_VaultVideo_Fragment' };

export type VaultItemFragment = VaultItem_VaultFolder_Fragment | VaultItem_VaultImage_Fragment | VaultItem_VaultTrack_Fragment | VaultItem_VaultVideo_Fragment;

export type UpsertUserContentViewMutationVariables = Exact<{
  input: MutationUpsertUserViewContentInput;
}>;


export type UpsertUserContentViewMutation = { upsertUserViewContent: { id: string } | null };

export type VideoItemFragment = { id: string, title: string | null, linkValue: string, createdAt: string, isFullVersionAvailable: boolean, blurredMediaUrl: string | null, vaultId: string, duration: number, parentVaultContentId: string | null, uploadedMedia: { id: string, mediaType: MediaType, url: string } | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, isUserArtistAdmin: boolean, artist: { id: string, profileImage: { id: string, url: string } | null } | null, activeSubscription: (
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'VideoItemFragment' };

export type SignedTrackDownloadUrlByTrackIdQueryVariables = Exact<{
  trackContentId: Scalars['UUID']['input'];
}>;


export type SignedTrackDownloadUrlByTrackIdQuery = { getSignedTrackDownloadContent: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'QueryGetSignedTrackDownloadContentSuccess', data: string } };

export type EditTrackSnippetFragment = (
  { id: string, duration: number, title: string | null, isFullVersionAvailable: boolean, freeTierSnippet: { id: string, startAt: number, endAt: number } | null, vault: { id: string, isUserArtistAdmin: boolean, artistProfile: { id: string, name: string } | null } }
  & { ' $fragmentRefs'?: { 'WaveformTrackInfoFragment': WaveformTrackInfoFragment } }
) & { ' $fragmentName'?: 'EditTrackSnippetFragment' };

export type MessageChannelViewFragment = { id: string, vault: { id: string, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null } | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } | null, messages: { pageInfo: { hasNextPage: boolean, endCursor: string | null }, edges: Array<{ cursor: string, node: (
        { source: MessageSource, id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment } }
      ) }> } } & { ' $fragmentName'?: 'MessageChannelViewFragment' };

export type SeeDetailsHeaderFragment = { id: string, vault: { id: string, artistProfile: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null } | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } | null } & { ' $fragmentName'?: 'SeeDetailsHeaderFragment' };

export type GetArtistMessagesQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetArtistMessagesQuery = { artistMessagesInChannel: { edges: Array<{ node: (
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type GetPinnedMessagesQueryVariables = Exact<{
  input: QueryMessageChannelByArtistHandleInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type GetPinnedMessagesQuery = { messageChannelByArtistHandle: { id: string, vault: { id: string, artist: { id: string } | null } | null, pinnedMessages: Array<(
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment;'PinnedMessageFragment': PinnedMessageFragment } }
    )> } };

export type GetAttachmentsInMessageChannelQueryVariables = Exact<{
  channelId: Scalars['UUID']['input'];
}>;


export type GetAttachmentsInMessageChannelQuery = { messageChannelAttachments: { pageInfo: { hasNextPage: boolean, endCursor: string | null }, edges: Array<{ node: { id: string, createdAt: string, uploadedMedia: { id: string, mediaType: MediaType, url: string } | null } }> } };

export type ArtistSubscribeViewFragment = (
  { id: string, mainVault: { id: string, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } }
  & { ' $fragmentRefs'?: { 'ArtistSubscribeHeaderInfoFragment': ArtistSubscribeHeaderInfoFragment } }
) & { ' $fragmentName'?: 'ArtistSubscribeViewFragment' };

export type ArtistInviteViewFragment = { id: string, name: string, linkValue: string, mainVaultId: string, profileImage: { id: string, url: string } | null } & { ' $fragmentName'?: 'ArtistInviteViewFragment' };

type InvitedUser_PrivateUser_Fragment = { id: string, displayName: string | null, username: string | null, createdAt: string, updatedAt: string, avatar: { id: string, url: string } | null } & { ' $fragmentName'?: 'InvitedUser_PrivateUser_Fragment' };

type InvitedUser_PublicUser_Fragment = { id: string, displayName: string | null, username: string | null, createdAt: string, updatedAt: string, avatar: { id: string, url: string } | null } & { ' $fragmentName'?: 'InvitedUser_PublicUser_Fragment' };

export type InvitedUserFragment = InvitedUser_PrivateUser_Fragment | InvitedUser_PublicUser_Fragment;

export type VaultInviteStatsFragment = { inviteCount: number, previewInvitedUsers: Array<{ ' $fragmentRefs'?: { 'InvitedUser_PrivateUser_Fragment': InvitedUser_PrivateUser_Fragment } } | { ' $fragmentRefs'?: { 'InvitedUser_PublicUser_Fragment': InvitedUser_PublicUser_Fragment } }> } & { ' $fragmentName'?: 'VaultInviteStatsFragment' };

export type TopInvitersFragment = { inviteCount: number, rank: number, inviter: { ' $fragmentRefs'?: { 'InvitedUser_PrivateUser_Fragment': InvitedUser_PrivateUser_Fragment } } | { ' $fragmentRefs'?: { 'InvitedUser_PublicUser_Fragment': InvitedUser_PublicUser_Fragment } } } & { ' $fragmentName'?: 'TopInvitersFragment' };

export type ArtistLandingFragment = { id: string, name: string, linkValue: string, profileImage: { id: string, url: string, dominantColor: string | null } | null } & { ' $fragmentName'?: 'ArtistLandingFragment' };

export type GetMessageChannelByArtistHandleQueryVariables = Exact<{
  input: QueryMessageChannelByArtistHandleInput;
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type GetMessageChannelByArtistHandleQuery = { messageChannelByArtistHandle: (
    { id: string, vault: { id: string, type: VaultType, price: number | null, contentCount: number, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null } | null, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } | null }
    & { ' $fragmentRefs'?: { 'MessageChannelViewFragment': MessageChannelViewFragment } }
  ) };

export type ArtistMainVaultViewFragment = { id: string, name: string, instagramHandle: string | null, description: string, customWebsiteUrl: string | null, spotifyUrl: string | null, tiktokHandle: string | null, linkValue: string, createdAt: string, profileImage: { id: string, url: string } | null, membershipCardImage: { id: string, url: string } | null, mainVault: { id: string, contentCount: number, memberCount: number, price: number | null, messageChannelId: string, isUserArtistAdmin: boolean, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string } | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } } & { ' $fragmentName'?: 'ArtistMainVaultViewFragment' };

export type GetUserLastViewedTimeOnVaultQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type GetUserLastViewedTimeOnVaultQuery = { getUserLastViewedTimeOnVault: string | null };

export type TrackThreadCommentsQueryVariables = Exact<{
  messageId: Scalars['UUID']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type TrackThreadCommentsQuery = { threadMessages: { edges: Array<{ node: (
        { id: string, createdAt: string }
        & { ' $fragmentRefs'?: { 'TrackCommentRowFragment': TrackCommentRowFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type SetUserLastViewedTimeOnVaultMutationVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type SetUserLastViewedTimeOnVaultMutation = { setUserLastViewedTimeOnVault: { __typename: 'MutationSetUserLastViewedTimeOnVaultSuccess' } | { __typename: 'NotFoundError', message: string } };

export type UpdateUserLastViewedMessageTimeOnVaultMutationVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type UpdateUserLastViewedMessageTimeOnVaultMutation = { updateUserLastViewedMessageTimeOnVault: { __typename: 'MutationUpdateUserLastViewedMessageTimeOnVaultSuccess', data: string } | { __typename: 'NotFoundError', message: string } };

export type VaultMessageUnreadCountsQueryVariables = Exact<{
  vaultIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type VaultMessageUnreadCountsQuery = { unreadMessageCounts: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryUnreadMessageCountsSuccess', data: Array<{ vaultId: string, unreadCount: number }> } };

export type VaultUpdateCountsQueryVariables = Exact<{
  vaultIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type VaultUpdateCountsQuery = { vaultUpdateCounts: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryVaultUpdateCountsSuccess', data: Array<{ vaultId: string, unseenCount: number }> } };

export type CreateSetupIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSetupIntentMutation = { createSetupIntent: { __typename: 'MutationCreateSetupIntentSuccess', data: { id: string, clientSecret: string } } | { __typename: 'StripeApiError', message: string } };

export type UpdatePaymentMethodMutationVariables = Exact<{
  input: MutationUpdatePaymentMethodInput;
}>;


export type UpdatePaymentMethodMutation = { updatePaymentMethod: boolean | null };

export type ManageSubscriptionFragment = { id: string, createdAt: string, updatedAt: string, status: VaultSubscriptionStatus, currentPeriodEnd: string | null, stripeSubscriptionId: string | null, vault: { type: VaultType, contentCount: number, id: string, price: number | null, artist: (
      { id: string, linkValue: string, name: string, profileImage: { id: string, url: string } | null }
      & { ' $fragmentRefs'?: { 'ArtistVaultRowFragment': ArtistVaultRowFragment } }
    ) | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null }, paymentMethod: (
    { id: string, active: boolean, last4: string }
    & { ' $fragmentRefs'?: { 'PaymentMethodRowFragment': PaymentMethodRowFragment } }
  ) | null } & { ' $fragmentName'?: 'ManageSubscriptionFragment' };

export type WaveformTrackInfoFragment = { id: string, normalizedPeaks: Array<number>, duration: number, parentVaultContentId: string | null, vaultId: string } & { ' $fragmentName'?: 'WaveformTrackInfoFragment' };

export type GetUnreadAnnouncementsCountQueryVariables = Exact<{
  vaultIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type GetUnreadAnnouncementsCountQuery = { unreadAnnouncementsCounts: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryUnreadAnnouncementsCountsSuccess', data: Array<{ vaultId: string, unreadCount: number }> } };

export type GetPaginatedVaultAnnouncementsQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type GetPaginatedVaultAnnouncementsQuery = { announcementsByArtistHandle: { edges: Array<{ node: (
        { content: string | null, id: string }
        & { ' $fragmentRefs'?: { 'AnnouncementRowFragment': AnnouncementRowFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type UpdateVaultUnreadAnnouncementCountMutationVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type UpdateVaultUnreadAnnouncementCountMutation = { setUserLastViewedTimeAnnouncement: { __typename: 'MutationSetUserLastViewedTimeAnnouncementSuccess', data: boolean } | { __typename: 'NotFoundError', message: string } };

export type ScheduledEventUpdatesSubscriptionVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type ScheduledEventUpdatesSubscription = { scheduledEventUpdates: { __typename: 'NotFoundError', message: string } | { __typename: 'SubscriptionScheduledEventUpdatesSuccess', data: { __typename: 'AnnouncementUpdateSubscription', scheduledEventId: string, scheduledAt: string } | { __typename: 'ReadyScheduledEventSubscription' } } };

export type AppleMusicDeveloperTokenQueryVariables = Exact<{
  origin: Scalars['URL']['input'];
  accessSecret?: InputMaybe<Scalars['String']['input']>;
}>;


export type AppleMusicDeveloperTokenQuery = { appleMusicDeveloperToken: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'QueryAppleMusicDeveloperTokenSuccess', data: string } };

export type ConnectAppleMusicMutationVariables = Exact<{
  input: MutationConnectAppleMusicInput;
}>;


export type ConnectAppleMusicMutation = { connectAppleMusic: { __typename: 'AppleMusicAPIError', message: string } | { __typename: 'AppleMusicAlreadyLinkedError', message: string } | { __typename: 'MutationConnectAppleMusicSuccess' } };

export type AppleMusicAuthConnectionQueryVariables = Exact<{
  userToken: Scalars['String']['input'];
}>;


export type AppleMusicAuthConnectionQuery = { appleMusicAuthConnection: { userToken: string, user: { id: string } | { id: string } | null } | null };

export type LinkAppleMusicMutationVariables = Exact<{
  input: MutationLinkAppleMusicInput;
}>;


export type LinkAppleMusicMutation = { linkAppleMusic: { __typename: 'AppleMusicAlreadyLinkedError', message: string } | { __typename: 'MutationLinkAppleMusicSuccess' } | { __typename: 'NotFoundError', message: string } };

export type UnlinkAppleMusicMutationVariables = Exact<{ [key: string]: never; }>;


export type UnlinkAppleMusicMutation = { unlinkAppleMusic: { __typename: 'MutationUnlinkAppleMusicSuccess' } | { __typename: 'NotFoundError', message: string } };

export type LastMembershipReceiptQueryVariables = Exact<{
  artistHandle?: InputMaybe<Scalars['String']['input']>;
  receiptType?: InputMaybe<ArtistMembershipReceiptTypenames>;
  releaseCampaignId: Scalars['UUID']['input'];
  spotifyAuthCode?: InputMaybe<Scalars['String']['input']>;
  appleMusicUserToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type LastMembershipReceiptQuery = { lastMembershipReceipt: (
    { id: string }
    & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment': MembershipReceipt_ArtistMembershipPlayStreamReceipt_Fragment } }
  ) | (
    { id: string }
    & { ' $fragmentRefs'?: { 'MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment': MembershipReceipt_ArtistMembershipPresaveReceipt_Fragment } }
  ) | null };

export type ArtistCampaignsQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  sort: SortDirection;
}>;


export type ArtistCampaignsQuery = { releaseCampaignsByArtistHandleByCreation: { edges: Array<{ node: (
        { id: string, status: ReleaseCampaignStatus, createdAt: string }
        & { ' $fragmentRefs'?: { 'EventItemFragment': EventItemFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type UserArtistMembershipQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
}>;


export type UserArtistMembershipQuery = { userArtistMembership: { artistId: string, serialNumber: number, unseenUpdates: number, createdAt: string, points: number, cardFirstTimeSeen: boolean, artist: { id: string, name: string, mainVaultId: string, linkValue: string, membershipCardImage: { id: string, url: string } | null, mainVault: { id: string, isUserArtistAdmin: boolean, activeSubscription: { id: string } | null } }, user: { id: string, displayName: string | null, username: string | null, avatar: { id: string, url: string } | null } | { id: string, displayName: string | null, username: string | null, avatar: { id: string, url: string } | null } } | null };

export type UserArtistReceiptsQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type UserArtistReceiptsQuery = { userArtistMembershipReceipts: { edges: Array<{ node: (
        { __typename: 'ArtistMembershipPlayStreamReceipt', id: string }
        & { ' $fragmentRefs'?: { 'CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment': CampaignReceiptItem_ArtistMembershipPlayStreamReceipt_Fragment } }
      ) | (
        { __typename: 'ArtistMembershipPresaveReceipt', id: string }
        & { ' $fragmentRefs'?: { 'CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment': CampaignReceiptItem_ArtistMembershipPresaveReceipt_Fragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type GetSpotifyAccessTokenQueryVariables = Exact<{
  authCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSpotifyAccessTokenQuery = { spotifyAuthAccessToken: { __typename: 'NotFoundError', message: string } | { __typename: 'QuerySpotifyAuthAccessTokenSuccess', data: string } | { __typename: 'SpotifyAPIError', message: string } | { __typename: 'SpotifyAuthInvalidatedError', message: string } };

export type SpotifyConnectMutationVariables = Exact<{
  input: ConnectSpotifyInput;
}>;


export type SpotifyConnectMutation = { connectSpotify: { __typename: 'MutationConnectSpotifySuccess', data: { spotifyUserId: string, product: SpotifyProductStatus | null } } | { __typename: 'SpotifyAPIError', message: string } };

export type SetSpotifyAuthStateRedirectMutationVariables = Exact<{
  input: MutationSetSpotifyStateRedirectInput;
}>;


export type SetSpotifyAuthStateRedirectMutation = { setSpotifyStateRedirect: { __typename: 'MutationSetSpotifyStateRedirectSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } };

export type GetSpotifyAuthStateRedirectQueryVariables = Exact<{
  input: QuerySpotifyAuthStateRedirectUrlInput;
}>;


export type GetSpotifyAuthStateRedirectQuery = { spotifyAuthStateRedirectUrl: { __typename: 'NotFoundError', message: string } | { __typename: 'QuerySpotifyAuthStateRedirectUrlSuccess', data: string } };

export type SpotifyLinkMutationVariables = Exact<{
  authCode: Scalars['String']['input'];
  overrideAccount: Scalars['Boolean']['input'];
}>;


export type SpotifyLinkMutation = { linkSpotify: { __typename: 'MutationLinkSpotifySuccess' } | { __typename: 'NotFoundError', message: string } | { __typename: 'SpotifyAlreadyLinkedError', message: string } };

export type SpotifyUnlinkMutationVariables = Exact<{ [key: string]: never; }>;


export type SpotifyUnlinkMutation = { unlinkSpotify: { __typename: 'MutationUnlinkSpotifySuccess' } | { __typename: 'NotFoundError', message: string } };

export type SpotifyAuthConnectionQueryVariables = Exact<{
  authCode: Scalars['String']['input'];
}>;


export type SpotifyAuthConnectionQuery = { spotifyAuthConnection: { spotifyUserId: string, product: SpotifyProductStatus | null } | null };

export type GetBatchedTracksViewedQueryVariables = Exact<{
  input: QueryVaultContentsAreSeenInput;
}>;


export type GetBatchedTracksViewedQuery = { vaultContentsAreSeen: Array<{ __typename: 'VaultContentViewed', vaultContentId: string, viewed: boolean | null }> };

export type ClickThroughSmsCampaignMutationVariables = Exact<{
  shortcode: Scalars['String']['input'];
}>;


export type ClickThroughSmsCampaignMutation = { clickThroughSmsCampaign: { __typename: 'MutationClickThroughSmsCampaignSuccess', data: boolean } | { __typename: 'NotFoundError', message: string } };

export type UpdateUserMutationVariables = Exact<{
  input: MutationUpdateUserInput;
}>;


export type UpdateUserMutation = { updateUser: { __typename: 'MutationUpdateUserSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'UserSettingsViewFragment': UserSettingsViewFragment } }
    ) } | { __typename: 'UsernameUnavailableError', message: string } | { __typename: 'ValidationError', message: string } };

export type UpdateUserRemoveFieldsMutationVariables = Exact<{
  input: MutationUpdateUserRemoveFieldsInput;
}>;


export type UpdateUserRemoveFieldsMutation = { updateUserRemoveFields: { __typename: 'MutationUpdateUserRemoveFieldsSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'UserSettingsViewFragment': UserSettingsViewFragment } }
    ) } | { __typename: 'ValidationError', message: string } };

export type SubscribeFreeTierMutationVariables = Exact<{
  input: MutationSubscribeToVaultFreeInput;
}>;


export type SubscribeFreeTierMutation = { subscribeToVaultFree: { __typename: 'MutationSubscribeToVaultFreeSuccess', data: { id: string, status: VaultSubscriptionStatus } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type MessageChannelUpdatesSubscriptionVariables = Exact<{
  messageChannelId: Scalars['UUID']['input'];
}>;


export type MessageChannelUpdatesSubscription = { messageChannelUpdates: { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'SubscriptionMessageChannelUpdatesSuccess', data: (
      { __typename: 'CreateMessageSubscription', id: string, source: MessageSource }
      & { ' $fragmentRefs'?: { 'MessageSubscriptionBubbleFragment': MessageSubscriptionBubbleFragment } }
    ) | { __typename: 'DeleteMessageSubscription', id: string } | { __typename: 'PinMessageSubscription', id: string, pinnedPriority: number | null } | { __typename: 'ReactionMessageSubscription', id: string, reactionType: MessageReactionType, created: boolean, userId: string, reactionTotalCount: number, isArtistReaction: boolean | null, asArtistId: string | null, emojiKeyword: string } | { __typename: 'ReadyMessageSubscription' } | { __typename: 'UnpinAllMessageSubscription', count: number } } };

export type GetMySubscribersQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  status?: InputMaybe<VaultSubscriptionStatus>;
  tierLevel?: InputMaybe<SubscriptionTierLevel>;
  sort: SortDirection;
}>;


export type GetMySubscribersQuery = { mySubscribers: { edges: Array<{ cursor: string, node: { id: string, createdAt: string, status: VaultSubscriptionStatus, vaultSubscriptionSourceText: string | null, vaultSubscriptionSourceType: VaultSubscriptionSourceType | null, email: string | null, phone: string | null, isTrial: boolean | null, tier: { __typename: 'FreeTier' } | { __typename: 'PaidTier' }, artistMembership: { receipts: number } | null, joinedViaReferralCode: { ' $fragmentRefs'?: { 'ReferralCodeInfoFragment': ReferralCodeInfoFragment } } | null, userLocation: { city: string | null, country: string | null, region: string | null } | null, user: (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
        ) | (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
        ) } }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type MySubScribersCsvUrlMutationVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type MySubScribersCsvUrlMutation = { getMySubscribersCsvUrl: { __typename: 'MutationGetMySubscribersCsvUrlSuccess', data: string } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'S3UploadError', message: string } | null };

export type MySubscribersSummaryQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type MySubscribersSummaryQuery = { mySubscribersSummary: { activeSubscriptionsCount: number, activePaidSubscriptionsCount: number, activeFreeSubscriptionsCount: number, activeFreeSubscriptionsCountLast7Days: number, activePaidSubscriptionsCountLast7Days: number, activeSubscriptionsCountLast7Days: number }, vaultFromId: { id: string, type: VaultType } | null };

export type ReferralCodeInfoFragment = { id: string, code: string, description: string | null, freeMonths: number, validUntil: string, isActive: boolean, postPurchaseMessage: string | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null, mainVault: { id: string, price: number | null, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null } } } & { ' $fragmentName'?: 'ReferralCodeInfoFragment' };

export type ArtistReferralCodeQueryVariables = Exact<{
  input: QueryGetArtistReferralCodeByCodeInput;
}>;


export type ArtistReferralCodeQuery = { getArtistReferralCodeByCode: (
    { id: string }
    & { ' $fragmentRefs'?: { 'ReferralCodeInfoFragment': ReferralCodeInfoFragment } }
  ) | null };

export type HighlightedFeatureQueryVariables = Exact<{
  vaultId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type HighlightedFeatureQuery = { highlightedFeature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } | null };

export type SeenFeatureMutationVariables = Exact<{
  featureKey: FeatureTypename;
}>;


export type SeenFeatureMutation = { seenFeature: { data: string } | { message: string } };

export type EditSnippetMutationVariables = Exact<{
  input: MutationEditVaultContentSnippetInput;
}>;


export type EditSnippetMutation = { editVaultContentSnippet: { __typename: 'MutationEditVaultContentSnippetSuccess' } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type SubscribeToVaultMutationVariables = Exact<{
  input: MutationSubscribeToVaultInput;
}>;


export type SubscribeToVaultMutation = { subscribeToVault: { __typename: 'MutationSubscribeToVaultSuccess', data: { id: string, status: VaultSubscriptionStatus, vault: { id: string } } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } | { __typename: 'StripeApiError', message: string } };

export type CancelSubscriptionMutationVariables = Exact<{
  input: MutationCancelSubscriptionInput;
}>;


export type CancelSubscriptionMutation = { cancelSubscription: { __typename: 'MutationCancelSubscriptionSuccess', data: { id: string } } | { __typename: 'NotFoundError', message: string } };

export type EnabledFeatureFragment = { feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } } & { ' $fragmentName'?: 'EnabledFeatureFragment' };

type TierEnabledFeatures_FreeTier_Fragment = { __typename: 'FreeTier', enabledFeatures: Array<{ ' $fragmentRefs'?: { 'EnabledFeatureFragment': EnabledFeatureFragment } }> } & { ' $fragmentName'?: 'TierEnabledFeatures_FreeTier_Fragment' };

type TierEnabledFeatures_PaidTier_Fragment = { __typename: 'PaidTier', enabledFeatures: Array<{ ' $fragmentRefs'?: { 'EnabledFeatureFragment': EnabledFeatureFragment } }> } & { ' $fragmentName'?: 'TierEnabledFeatures_PaidTier_Fragment' };

export type TierEnabledFeaturesFragment = TierEnabledFeatures_FreeTier_Fragment | TierEnabledFeatures_PaidTier_Fragment;

export type ActiveSubscriptionFeaturesFragment = { tier: { ' $fragmentRefs'?: { 'TierEnabledFeatures_FreeTier_Fragment': TierEnabledFeatures_FreeTier_Fragment } } | { ' $fragmentRefs'?: { 'TierEnabledFeatures_PaidTier_Fragment': TierEnabledFeatures_PaidTier_Fragment } } } & { ' $fragmentName'?: 'ActiveSubscriptionFeaturesFragment' };

export type UpdateUpsellInterstitialsMutationVariables = Exact<{
  input: MutationUpdateUpsellInterstitialsInput;
}>;


export type UpdateUpsellInterstitialsMutation = { updateUpsellInterstitials: { firstChat: boolean, firstSnippetShare: boolean, firstTrack: boolean } };

export type VaultContentPaginationQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  contentType?: InputMaybe<VaultContentType>;
}>;


export type VaultContentPaginationQuery = { vaultContent: { edges: Array<{ node: (
        { __typename: 'VaultFolder', id: string, title: string | null, createdAt: string, vault: { id: string, activeSubscription: (
            { id: string }
            & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
          ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultFolder_Fragment': VaultItem_VaultFolder_Fragment } }
      ) | (
        { __typename: 'VaultImage', id: string, title: string | null, createdAt: string, vault: { id: string, activeSubscription: (
            { id: string }
            & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
          ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultImage_Fragment': VaultItem_VaultImage_Fragment } }
      ) | (
        { __typename: 'VaultTrack', duration: number, id: string, title: string | null, createdAt: string, vault: { id: string, activeSubscription: (
            { id: string }
            & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
          ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'TrackRowInfoFragment': TrackRowInfoFragment;'TrackFileInfoFragment': TrackFileInfoFragment;'VaultItem_VaultTrack_Fragment': VaultItem_VaultTrack_Fragment } }
      ) | (
        { __typename: 'VaultVideo', id: string, title: string | null, createdAt: string, vault: { id: string, activeSubscription: (
            { id: string }
            & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
          ) | null }, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultVideo_Fragment': VaultItem_VaultVideo_Fragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type VaultContentByFolderQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type VaultContentByFolderQuery = { vaultContentBySortTime: { edges: Array<{ cursor: string, node: (
        { __typename: 'VaultFolder', id: string, title: string | null, createdAt: string, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultFolder_Fragment': VaultItem_VaultFolder_Fragment } }
      ) | (
        { __typename: 'VaultImage', id: string, title: string | null, createdAt: string, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultImage_Fragment': VaultItem_VaultImage_Fragment } }
      ) | (
        { __typename: 'VaultTrack', id: string, title: string | null, createdAt: string, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'TrackFileInfoFragment': TrackFileInfoFragment;'VaultItem_VaultTrack_Fragment': VaultItem_VaultTrack_Fragment } }
      ) | (
        { __typename: 'VaultVideo', id: string, title: string | null, createdAt: string, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultVideo_Fragment': VaultItem_VaultVideo_Fragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

type VaultContentLandingPage_VaultFolder_Fragment = { __typename: 'VaultFolder', id: string, isFullVersionAvailable: boolean, title: string | null, linkValue: string, createdAt: string, parentVaultContentId: string | null, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null, membershipCardImage: { id: string, url: string } | null } | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'VaultContentLandingPage_VaultFolder_Fragment' };

type VaultContentLandingPage_VaultImage_Fragment = { __typename: 'VaultImage', id: string, blurredMediaUrl: string | null, fileTypeExtension: string | null, isFullVersionAvailable: boolean, title: string | null, linkValue: string, createdAt: string, parentVaultContentId: string | null, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null, membershipCardImage: { id: string, url: string } | null } | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'VaultContentLandingPage_VaultImage_Fragment' };

type VaultContentLandingPage_VaultTrack_Fragment = (
  { __typename: 'VaultTrack', id: string, normalizedPeaks: Array<number>, createdAt: string, duration: number, title: string | null, linkValue: string, caption: string | null, commentMessageCount: number, isFullVersionAvailable: boolean, parentVaultContentId: string | null, snippetVideo: { id: string, url: string } | null, freeTierSnippet: { id: string, startAt: number, endAt: number } | null, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null, membershipCardImage: { id: string, url: string } | null } | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null }, commentPinnedMessage: (
    { id: string, content: string }
    & { ' $fragmentRefs'?: { 'TrackCommentRowFragment': TrackCommentRowFragment } }
  ) | null, commentCaptionMessage: (
    { id: string, content: string }
    & { ' $fragmentRefs'?: { 'TrackCommentRowFragment': TrackCommentRowFragment } }
  ) | null, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
  & { ' $fragmentRefs'?: { 'PresaveSpotifyInfoFragment': PresaveSpotifyInfoFragment } }
) & { ' $fragmentName'?: 'VaultContentLandingPage_VaultTrack_Fragment' };

type VaultContentLandingPage_VaultVideo_Fragment = { __typename: 'VaultVideo', id: string, blurredMediaUrl: string | null, duration: number, fileTypeExtension: string | null, isFullVersionAvailable: boolean, title: string | null, linkValue: string, createdAt: string, parentVaultContentId: string | null, vault: { id: string, type: VaultType, messageChannelId: string, price: number | null, isUserArtistAdmin: boolean, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null, membershipCardImage: { id: string, url: string } | null } | null, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null } } & { ' $fragmentName'?: 'VaultContentLandingPage_VaultVideo_Fragment' };

export type VaultContentLandingPageFragment = VaultContentLandingPage_VaultFolder_Fragment | VaultContentLandingPage_VaultImage_Fragment | VaultContentLandingPage_VaultTrack_Fragment | VaultContentLandingPage_VaultVideo_Fragment;

export type VaultContentByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type VaultContentByIdQuery = { vaultContentById: (
    { __typename: 'VaultFolder', id: string }
    & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultFolder_Fragment': VaultContentLandingPage_VaultFolder_Fragment } }
  ) | (
    { __typename: 'VaultImage', id: string }
    & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultImage_Fragment': VaultContentLandingPage_VaultImage_Fragment } }
  ) | (
    { __typename: 'VaultTrack', id: string }
    & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultTrack_Fragment': VaultContentLandingPage_VaultTrack_Fragment } }
  ) | (
    { __typename: 'VaultVideo', id: string }
    & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultVideo_Fragment': VaultContentLandingPage_VaultVideo_Fragment } }
  ) | null };

export type VaultContentBySlugQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
  vaultContentSlug: Scalars['String']['input'];
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type VaultContentBySlugQuery = { vaultContentBySlug: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryVaultContentBySlugSuccess', data: (
      { __typename: 'VaultFolder', id: string }
      & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultFolder_Fragment': VaultContentLandingPage_VaultFolder_Fragment } }
    ) | (
      { __typename: 'VaultImage', id: string }
      & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultImage_Fragment': VaultContentLandingPage_VaultImage_Fragment } }
    ) | (
      { __typename: 'VaultTrack', id: string }
      & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultTrack_Fragment': VaultContentLandingPage_VaultTrack_Fragment } }
    ) | (
      { __typename: 'VaultVideo', id: string }
      & { ' $fragmentRefs'?: { 'VaultContentLandingPage_VaultVideo_Fragment': VaultContentLandingPage_VaultVideo_Fragment } }
    ) } | null };

export type VaultContentByFolderPositionQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  parentVaultContentId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type VaultContentByFolderPositionQuery = { vaultContentByFolderPosition: { edges: Array<{ cursor: string, node: (
        { __typename: 'VaultFolder', id: string, title: string | null, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultFolder_Fragment': VaultItem_VaultFolder_Fragment } }
      ) | (
        { __typename: 'VaultImage', id: string, title: string | null, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultImage_Fragment': VaultItem_VaultImage_Fragment } }
      ) | (
        { __typename: 'VaultTrack', id: string, title: string | null, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'TrackFileInfoFragment': TrackFileInfoFragment;'VaultItem_VaultTrack_Fragment': VaultItem_VaultTrack_Fragment } }
      ) | (
        { __typename: 'VaultVideo', id: string, title: string | null, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
        & { ' $fragmentRefs'?: { 'VaultItem_VaultVideo_Fragment': VaultItem_VaultVideo_Fragment } }
      ) }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type VaultThemeByArtistHandleQueryVariables = Exact<{
  input: QueryArtistByLinkInput;
}>;


export type VaultThemeByArtistHandleQuery = { artistByLink: { id: string, mainVaultId: string, name: string, linkValue: string, mainVault: { id: string, type: VaultType, accentColor: any, backgroundColor: any, logoImage: { id: string, url: string } | null }, profileImage: { id: string, url: string } | null } | null };

export type VaultThemeByVaultIdQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type VaultThemeByVaultIdQuery = { vaultFromId: { id: string, type: VaultType, accentColor: any, backgroundColor: any, logoImage: { id: string, url: string } | null, artistProfile: { id: string, name: string, profileImage: { id: string, url: string } | null } | null } | null };

export type MoveToFolderMutationVariables = Exact<{
  input: MutationMoveContentsToVaultFolderInput;
}>;


export type MoveToFolderMutation = { moveContentsToVaultFolder: { __typename: 'MutationMoveContentsToVaultFolderSuccess', data: Array<{ __typename: 'VaultFolder', id: string, vaultId: string, title: string | null } | { __typename: 'VaultImage', id: string, vaultId: string, title: string | null } | { __typename: 'VaultTrack', id: string, vaultId: string, title: string | null } | { __typename: 'VaultVideo', id: string, vaultId: string, title: string | null }> } | { __typename: 'NotFoundError', message: string } | { __typename: 'ValidationError', message: string } };

export type RemoveVaultContentsMutationVariables = Exact<{
  input: MutationRemoveVaultContentsInput;
}>;


export type RemoveVaultContentsMutation = { removeVaultContents: { __typename: 'MutationRemoveVaultContentsSuccess', data: Array<{ __typename: 'VaultFolder', id: string } | { __typename: 'VaultImage', id: string } | { __typename: 'VaultTrack', id: string } | { __typename: 'VaultVideo', id: string }> } | { __typename: 'NotFoundError', message: string } };

export type CreateVaultFolderMutationVariables = Exact<{
  input: MutationCreateVaultFolderInput;
}>;


export type CreateVaultFolderMutation = { createVaultFolder: { __typename: 'MutationCreateVaultFolderSuccess', data: { id: string } | { id: string } | { id: string } | { id: string } } | { __typename: 'NotFoundError' } };

export type EditVaultFolderMutationVariables = Exact<{
  input: MutationUpdateVaultFolderInput;
}>;


export type EditVaultFolderMutation = { updateVaultFolder: { __typename: 'MutationUpdateVaultFolderSuccess' } | { __typename: 'NotFoundError' } };

export type AuthUserFragment = (
  { id: string, phone: string | null, email: string | null, zipCode: string | null, username: string | null, isInternal: boolean, isAdmin: boolean, isTestPhoneUser: boolean, isOfficialVaultUser: boolean, highestSubscriptionLevel: SubscriptionTierLevel | null, displayName: string | null, createdAt: string, inviteCode: string, pushNotificationsConfigurable: boolean, spotifyAuthConnection: { spotifyUserId: string, product: SpotifyProductStatus | null } | null, upsellInterstitials: { firstChat: boolean, firstSnippetShare: boolean, firstTrack: boolean }, adminArtists: Array<{ artistId: string, artistMainLinkValue: string, artistLinks: Array<string>, artistMainVaultId: string, artistMainVaultType: VaultType, artistName: string, role: ArtistAdminRole, artistProfileImage: { id: string, url: string, dominantColor: string | null } | null }> | null, avatar: { id: string, url: string, dominantColor: string | null, mediaType: MediaType } | null, payee: { id: string, email: string, phone: string | null, hasCompletedOnboarding: boolean } | null, appleMusicAuthConnections: Array<{ userToken: string }> }
  & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
) & { ' $fragmentName'?: 'AuthUserFragment' };

export type AuthUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthUserQuery = { currentUser: { __typename: 'AccountDeletedPending', message: string } | { __typename: 'QueryCurrentUserSuccess', data: (
      { privyUserId: string | null, id: string, username: string | null, email: string | null, phone: string | null, highestSubscriptionLevel: SubscriptionTierLevel | null, isInternal: boolean }
      & { ' $fragmentRefs'?: { 'AuthUserFragment': AuthUserFragment } }
    ) } };

export type CheckInMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckInMutation = { userCheckIn: null | null };

export type ResetTestPhoneUserMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetTestPhoneUserMutation = { resetSelfTestUser: Array<number> };

export type CreateSubscriptionPaymentIntentMutationVariables = Exact<{
  input: MutationCreateSubscriptionPaymentIntentInput;
}>;


export type CreateSubscriptionPaymentIntentMutation = { createSubscriptionPaymentIntent: { __typename: 'ArtistUnfinishedExternalAccount', message: string } | { __typename: 'MutationCreateSubscriptionPaymentIntentSuccess', data: { id: string, client_secret: string, subscriptionId: string } } | { __typename: 'NotFoundError', message: string } | { __typename: 'StripeApiError', message: string } };

export type GetArtistsToExploreQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type GetArtistsToExploreQuery = { unsubscribedArtists: { edges: Array<{ cursor: string, node: (
        { id: string }
        & { ' $fragmentRefs'?: { 'BubbleArtistFragment': BubbleArtistFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type CampaignByIdQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  artistHandle: Scalars['String']['input'];
}>;


export type CampaignByIdQuery = { releaseCampaignByIdOrSlug: (
    { id: string, linkValue: string, status: ReleaseCampaignStatus }
    & { ' $fragmentRefs'?: { 'InsightHeaderFragment': InsightHeaderFragment } }
  ) | null };

export type CampaignInsightsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  artistHandle: Scalars['String']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CampaignInsightsQuery = { allArtistMembershipReceipts: { edges: Array<{ cursor: string, node: { id: string, user: (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
        ) | (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
        ), membership: { createdAt: string, vaultSubscription: { id: string, createdAt: string } | null } } | { id: string, user: (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PrivateUser_Fragment': UserRow_PrivateUser_Fragment } }
        ) | (
          { id: string }
          & { ' $fragmentRefs'?: { 'UserRow_PublicUser_Fragment': UserRow_PublicUser_Fragment } }
        ), membership: { createdAt: string, vaultSubscription: { id: string, createdAt: string } | null } } }>, pageInfo: { hasNextPage: boolean, endCursor: string | null } } };

export type InsightHeaderFragment = { id: string, title: string, initialReleaseImageUrl: string | null, description: string | null, releaseDate: string | null, newSubscriptionCount: number, receiptCount: number, coverImage: { id: string, url: string } | null, artist: { id: string, profileImage: { id: string, url: string } | null } } & { ' $fragmentName'?: 'InsightHeaderFragment' };

export type GetArtistInvitesPageQueryVariables = Exact<{
  input: QueryArtistByLinkInput;
}>;


export type GetArtistInvitesPageQuery = { artistByLink: (
    { id: string, name: string, mainVaultId: string, profileImage: { id: string, url: string } | null }
    & { ' $fragmentRefs'?: { 'ArtistInviteViewFragment': ArtistInviteViewFragment } }
  ) | null };

export type UserVaultInviteStatsQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type UserVaultInviteStatsQuery = { userVaultInviteStats: { ' $fragmentRefs'?: { 'VaultInviteStatsFragment': VaultInviteStatsFragment } } };

export type TopVaultInvitersQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
}>;


export type TopVaultInvitersQuery = { topVaultInviters: Array<{ ' $fragmentRefs'?: { 'TopInvitersFragment': TopInvitersFragment } }> };

export type MyVaultSubscriptionFragment = { id: string, vault: { id: string, artist: (
      { id: string }
      & { ' $fragmentRefs'?: { 'BubbleArtistFragment': BubbleArtistFragment } }
    ) | null } } & { ' $fragmentName'?: 'MyVaultSubscriptionFragment' };

export type MyArtistVaultQueryVariables = Exact<{
  artistId: Scalars['UUID']['input'];
}>;


export type MyArtistVaultQuery = { artistById: (
    { id: string }
    & { ' $fragmentRefs'?: { 'BubbleArtistFragment': BubbleArtistFragment } }
  ) };

export type GetActiveVaultsSubscriptionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetActiveVaultsSubscriptionsQuery = { activeVaultSubscriptions: { edges: Array<{ cursor: string, node: (
        { id: string }
        & { ' $fragmentRefs'?: { 'MenuVaultSubscriptionFragment': MenuVaultSubscriptionFragment;'MyVaultSubscriptionFragment': MyVaultSubscriptionFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type PinnedMessageChannelQueryVariables = Exact<{
  input: QueryMessageChannelByArtistHandleInput;
  asArtistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type PinnedMessageChannelQuery = { messageChannelByArtistHandle: (
    { id: string, vault: { id: string, artist: { id: string } | null, activeSubscription: (
        { id: string }
        & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
      ) | null, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }> } | null, pinnedMessages: Array<(
      { id: string, createdAt: string }
      & { ' $fragmentRefs'?: { 'MessageBubbleFragment': MessageBubbleFragment;'PinnedMessageFragment': PinnedMessageFragment } }
    )> }
    & { ' $fragmentRefs'?: { 'MessageChannelLayoutInfoFragment': MessageChannelLayoutInfoFragment } }
  ) };

export type UnpinAllMessagesMutationVariables = Exact<{
  input: MutationRemoveAllPinnedMessageInput;
}>;


export type UnpinAllMessagesMutation = { removeAllPinnedMessage: { __typename: 'MutationRemoveAllPinnedMessageSuccess' } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type GetMessageChannelQueryVariables = Exact<{
  input: QueryMessageChannelByArtistHandleInput;
}>;


export type GetMessageChannelQuery = { messageChannelByArtistHandle: { ' $fragmentRefs'?: { 'SeeDetailsHeaderFragment': SeeDetailsHeaderFragment } } };

export type UpdateUserOnboardingMutationVariables = Exact<{
  input: MutationUpdateUserInput;
}>;


export type UpdateUserOnboardingMutation = { updateUser: { __typename: 'MutationUpdateUserSuccess', data: { id: string, username: string | null } } | { __typename: 'UsernameUnavailableError', message: string } | { __typename: 'ValidationError', message: string } };

export type ArtistProfileImageByHandleQueryVariables = Exact<{
  link: Scalars['String']['input'];
}>;


export type ArtistProfileImageByHandleQuery = { artistLink: { artist: { id: string, profileImage: { id: string, url: string } | null } } | null };

export type GetSubscribePageArtistQueryVariables = Exact<{
  link: Scalars['String']['input'];
}>;


export type GetSubscribePageArtistQuery = { artistLink: { artist: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ArtistSubscribeViewFragment': ArtistSubscribeViewFragment } }
    ) } | null };

export type GetSubscriptionsForArtistNotificationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type GetSubscriptionsForArtistNotificationsQuery = { allVaultSubscriptionsByPriority: { edges: Array<{ cursor: string, node: { id: string, vault: { id: string, artist: (
            { id: string, name: string }
            & { ' $fragmentRefs'?: { 'ArtistVaultRowFragment': ArtistVaultRowFragment } }
          ) | null } } }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type BillingRowFragment = { id: string | null, timestamp: number, amount: number, status: string, recipientName: string | null } & { ' $fragmentName'?: 'BillingRowFragment' };

export type GetBillingQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetBillingQuery = { billing: { __typename: 'QueryBillingSuccess', data: { pageInfo: { endCursor: string | null, hasNextPage: boolean }, edges: Array<{ cursor: string, node: (
          { id: string | null }
          & { ' $fragmentRefs'?: { 'BillingRowFragment': BillingRowFragment } }
        ) }> } } | { __typename: 'StripeApiError', message: string } };

export type GetMyEarningsBreakdownQueryVariables = Exact<{
  artistId?: InputMaybe<Scalars['UUID']['input']>;
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}>;


export type GetMyEarningsBreakdownQuery = { earningsBreakdown: Array<{ payeeEarningAmount: number, vaultArtistName: string, vaultContentTitle: string }> };

export type GetMyEarningsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  artistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type GetMyEarningsQuery = { myEarnings: { __typename: 'QueryMyEarningsSuccess', data: { pageInfo: { endCursor: string | null, hasNextPage: boolean }, edges: Array<{ cursor: string, node: (
          { id: string | null, month: number, year: number }
          & { ' $fragmentRefs'?: { 'EarningsRowFragment': EarningsRowFragment } }
        ) }> } } | { __typename: 'StripeApiError', message: string } };

export type ArtistSettingsViewFragment = { id: string, name: string, description: string, customWebsiteUrl: string | null, spotifyUrl: string | null, instagramHandle: string | null, tiktokHandle: string | null, profileImage: { id: string, url: string, mediaType: MediaType } | null } & { ' $fragmentName'?: 'ArtistSettingsViewFragment' };

export type UpdateArtistMutationVariables = Exact<{
  input: MutationUpdateArtistProfileInput;
}>;


export type UpdateArtistMutation = { updateArtistProfile: { __typename: 'MutationUpdateArtistProfileSuccess', data: { ' $fragmentRefs'?: { 'ArtistSettingsViewFragment': ArtistSettingsViewFragment } } } | { __typename: 'NotFoundError', message: string } | { __typename: 'UsernameUnavailableError', message: string } | { __typename: 'ValidationError', message: string } };

export type ArtistProfileSettingsQueryVariables = Exact<{
  artistId: Scalars['UUID']['input'];
}>;


export type ArtistProfileSettingsQuery = { artistById: (
    { id: string }
    & { ' $fragmentRefs'?: { 'ArtistSettingsViewFragment': ArtistSettingsViewFragment } }
  ) };

export type UserSettingsViewFragment = { id: string, username: string | null, displayName: string | null, email: string | null, zipCode: string | null, avatar: { id: string, url: string, mediaType: MediaType } | null, userProvidedGoogleLocation: { googlePlaceId: string, googlePlaceInputLabel: string | null, city: string | null, region: string | null, regionShort: string | null, country: string | null, isoCountry: string | null } | null } & { ' $fragmentName'?: 'UserSettingsViewFragment' };

export type UserProfileSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileSettingsQuery = { currentUser: { __typename: 'AccountDeletedPending', message: string } | { __typename: 'QueryCurrentUserSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'UserSettingsViewFragment': UserSettingsViewFragment } }
    ) } };

export type ArtistUserNotificationSettingsFragment = { isPushNotificationEnabled: boolean, isSmsEnabled: boolean } & { ' $fragmentName'?: 'ArtistUserNotificationSettingsFragment' };

export type GetArtistUserNotificationSettingsQueryVariables = Exact<{
  artistId: Scalars['UUID']['input'];
}>;


export type GetArtistUserNotificationSettingsQuery = { artistUserSettings: { artistId: string, artist: { id: string, name: string }, notificationSettings: { ' $fragmentRefs'?: { 'ArtistUserNotificationSettingsFragment': ArtistUserNotificationSettingsFragment } } } };

export type UpdateArtistUserNotificationSettingsMutationVariables = Exact<{
  input: MutationUpdateArtistUserSettingsInput;
}>;


export type UpdateArtistUserNotificationSettingsMutation = { updateArtistUserSettings: { artistId: string, notificationSettings: { isPushNotificationEnabled: boolean, isSmsEnabled: boolean } } };

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentMethodsQuery = { paymentMethods: Array<(
    { id: string }
    & { ' $fragmentRefs'?: { 'PaymentMethodRowFragment': PaymentMethodRowFragment } }
  )> };

export type RemovePaymentMethodMutationVariables = Exact<{
  input: MutationRemovePaymentMethodInput;
}>;


export type RemovePaymentMethodMutation = { removePaymentMethod: { id: string } | null };

export type GetSubscriptionByIdQueryVariables = Exact<{
  input: QueryVaultSubscriptionInput;
}>;


export type GetSubscriptionByIdQuery = { vaultSubscription: { __typename: 'NotFoundError', message: string } | { __typename: 'QueryVaultSubscriptionSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ManageSubscriptionFragment': ManageSubscriptionFragment } }
    ) } };

export type UserNotificationSettingsFragment = { id: string, phone: string | null, notificationSettings: { isEmailEnabled: boolean, isSmsEnabled: boolean, smsNotifications: { newArtistTrack: boolean, newSubscriber: boolean } } } & { ' $fragmentName'?: 'UserNotificationSettingsFragment' };

export type UserNotificationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationSettingsQuery = { currentUser: { __typename: 'AccountDeletedPending', message: string } | { __typename: 'QueryCurrentUserSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'UserNotificationSettingsFragment': UserNotificationSettingsFragment } }
    ) } };

export type UpdateSmsNotificationSettingsMutationVariables = Exact<{
  input: MutationUpdateSmsNotificationsSettingsInput;
}>;


export type UpdateSmsNotificationSettingsMutation = { updateSmsNotificationsSettings: (
    { id: string }
    & { ' $fragmentRefs'?: { 'UserNotificationSettingsFragment': UserNotificationSettingsFragment } }
  ) };

export type CreateConnectedAccountMutationVariables = Exact<{
  artistId?: InputMaybe<Scalars['UUID']['input']>;
  stripeReturnUrl?: InputMaybe<Scalars['URL']['input']>;
}>;


export type CreateConnectedAccountMutation = { createConnectedAccount: { __typename: 'MutationCreateConnectedAccountSuccess', data: { id: string, accountLinkUrl: string } } | { __typename: 'StripeApiError', message: string } };

export type GetExternalAccountQueryVariables = Exact<{
  artistId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type GetExternalAccountQuery = { externalAccount: { __typename: 'ArtistUnfinishedExternalAccount', message: string } | { __typename: 'QueryExternalAccountSuccess', data: { id: string, brand: string | null, type: ExternalAccountType, last4: string } } | { __typename: 'StripeApiError' } | null };

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAccountMutation = { deleteAccount: { __typename: 'PrivateUser', id: string } | { __typename: 'PublicUser', id: string } };

export type SubscriptionRowFragment = { id: string, status: VaultSubscriptionStatus, createdAt: string, updatedAt: string, currentPeriodEnd: string | null, isTrial: boolean | null, tier: { __typename: 'FreeTier' } | { __typename: 'PaidTier' }, vault: { contentCount: number, id: string, artist: (
      { linkValue: string }
      & { ' $fragmentRefs'?: { 'ArtistVaultRowFragment': ArtistVaultRowFragment } }
    ) | null } } & { ' $fragmentName'?: 'SubscriptionRowFragment' };

export type GetSubscriptionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type GetSubscriptionsQuery = { allVaultSubscriptionsByPriority: { edges: Array<{ cursor: string, node: (
        { id: string }
        & { ' $fragmentRefs'?: { 'SubscriptionRowFragment': SubscriptionRowFragment } }
      ) }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type CreateArtistMutationVariables = Exact<{
  input: MutationCreateArtistInput;
}>;


export type CreateArtistMutation = { createArtist: { __typename: 'Artist', id: string, linkValue: string } };

export type TrackSnippetDetailsByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
}>;


export type TrackSnippetDetailsByIdQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, title: string | null, vault: { id: string, artistId: string | null } } | { __typename: 'VaultImage', id: string, title: string | null, vault: { id: string, artistId: string | null } } | (
    { __typename: 'VaultTrack', id: string, title: string | null, vault: { id: string, artistId: string | null } }
    & { ' $fragmentRefs'?: { 'EditTrackSnippetFragment': EditTrackSnippetFragment } }
  ) | { __typename: 'VaultVideo', id: string, title: string | null, vault: { id: string, artistId: string | null } } | null };

export type TrackDetailsByIdQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
}>;


export type TrackDetailsByIdQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, vault: { id: string, artistId: string | null } } | { __typename: 'VaultImage', id: string, vault: { id: string, artistId: string | null } } | { __typename: 'VaultTrack', id: string, title: string | null, caption: string | null, downloadEnabled: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }>, vault: { id: string, artistId: string | null } } | { __typename: 'VaultVideo', id: string, vault: { id: string, artistId: string | null } } | null };

export type EditVaultContentMutationVariables = Exact<{
  input: MutationEditVaultContentInput;
}>;


export type EditVaultContentMutation = { editVaultContent: { __typename: 'MutationEditVaultContentSuccess', data: { id: string } | { id: string } | (
      { id: string }
      & { ' $fragmentRefs'?: { 'TrackFileInfoFragment': TrackFileInfoFragment;'TrackRowInfoFragment': TrackRowInfoFragment;'TrackItemFragment': TrackItemFragment } }
    ) | { id: string } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type CreateVaultContentMutationVariables = Exact<{
  input: MutationCreateVaultContentInput;
}>;


export type CreateVaultContentMutation = { createVaultContent: { __typename: 'AlreadyExistsError' } | { __typename: 'ContentNotifierPublishError' } | { __typename: 'MutationCreateVaultContentSuccess', data: (
      { __typename: 'VaultFolder', id: string, title: string | null, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultFolder_Fragment': VaultItem_VaultFolder_Fragment } }
    ) | (
      { __typename: 'VaultImage', id: string, title: string | null, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultImage_Fragment': VaultItem_VaultImage_Fragment } }
    ) | (
      { __typename: 'VaultTrack', id: string, title: string | null, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'TrackFileInfoFragment': TrackFileInfoFragment;'VaultItem_VaultTrack_Fragment': VaultItem_VaultTrack_Fragment } }
    ) | (
      { __typename: 'VaultVideo', id: string, title: string | null, createdAt: string, folderPosition: number, isFullVersionAvailable: boolean, featureAccess: Array<{ feature: { __typename: 'FreeVaultContent' } | { __typename: 'PaidVaultContent' } }> }
      & { ' $fragmentRefs'?: { 'VaultItem_VaultVideo_Fragment': VaultItem_VaultVideo_Fragment } }
    ) } | { __typename: 'NotAuthorizedError' } | { __typename: 'NotFoundError' } | { __typename: 'TranscodePublishError' } | { __typename: 'ValidationError' } | null };

type FolderPage_VaultFolder_Fragment = { __typename: 'VaultFolder', id: string, childrenVaultCount: number, vaultId: string, title: string | null, vault: { price: number | null, messageChannelId: string, id: string, contentCount: number, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null } | null } } & { ' $fragmentName'?: 'FolderPage_VaultFolder_Fragment' };

type FolderPage_VaultImage_Fragment = { __typename: 'VaultImage', id: string, vaultId: string, title: string | null, vault: { price: number | null, messageChannelId: string, id: string, contentCount: number, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null } | null } } & { ' $fragmentName'?: 'FolderPage_VaultImage_Fragment' };

type FolderPage_VaultTrack_Fragment = { __typename: 'VaultTrack', id: string, vaultId: string, title: string | null, vault: { price: number | null, messageChannelId: string, id: string, contentCount: number, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null } | null } } & { ' $fragmentName'?: 'FolderPage_VaultTrack_Fragment' };

type FolderPage_VaultVideo_Fragment = { __typename: 'VaultVideo', id: string, vaultId: string, title: string | null, vault: { price: number | null, messageChannelId: string, id: string, contentCount: number, activeSubscription: (
      { id: string }
      & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
    ) | null, artist: { id: string, name: string, linkValue: string, profileImage: { id: string, url: string } | null } | null } } & { ' $fragmentName'?: 'FolderPage_VaultVideo_Fragment' };

export type FolderPageFragment = FolderPage_VaultFolder_Fragment | FolderPage_VaultImage_Fragment | FolderPage_VaultTrack_Fragment | FolderPage_VaultVideo_Fragment;

export type GetFolderInformationQueryVariables = Exact<{
  folderId: Scalars['UUID']['input'];
}>;


export type GetFolderInformationQuery = { __typename: 'Query', vaultContentById: (
    { id: string }
    & { ' $fragmentRefs'?: { 'FolderPage_VaultFolder_Fragment': FolderPage_VaultFolder_Fragment } }
  ) | (
    { id: string }
    & { ' $fragmentRefs'?: { 'FolderPage_VaultImage_Fragment': FolderPage_VaultImage_Fragment } }
  ) | (
    { id: string }
    & { ' $fragmentRefs'?: { 'FolderPage_VaultTrack_Fragment': FolderPage_VaultTrack_Fragment } }
  ) | (
    { id: string }
    & { ' $fragmentRefs'?: { 'FolderPage_VaultVideo_Fragment': FolderPage_VaultVideo_Fragment } }
  ) | null };

export type GetFolderInformationBySlugQueryVariables = Exact<{
  folderSlug: Scalars['String']['input'];
  artistHandle: Scalars['String']['input'];
}>;


export type GetFolderInformationBySlugQuery = { __typename: 'Query', vaultContentBySlug: { __typename: 'NotFoundError' } | { __typename: 'QueryVaultContentBySlugSuccess', data: (
      { id: string }
      & { ' $fragmentRefs'?: { 'FolderPage_VaultFolder_Fragment': FolderPage_VaultFolder_Fragment } }
    ) | (
      { id: string }
      & { ' $fragmentRefs'?: { 'FolderPage_VaultImage_Fragment': FolderPage_VaultImage_Fragment } }
    ) | (
      { id: string }
      & { ' $fragmentRefs'?: { 'FolderPage_VaultTrack_Fragment': FolderPage_VaultTrack_Fragment } }
    ) | (
      { id: string }
      & { ' $fragmentRefs'?: { 'FolderPage_VaultVideo_Fragment': FolderPage_VaultVideo_Fragment } }
    ) } | null };

export type GetAllFoldersQueryVariables = Exact<{
  vaultId: Scalars['UUID']['input'];
  folderId?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type GetAllFoldersQuery = { vaultContentBySortTime: { edges: Array<{ node: (
        { __typename: 'VaultFolder', id: string, title: string | null, vault: { id: string, artistProfile: { id: string, linkValue: string } | null } }
        & { ' $fragmentRefs'?: { 'FolderItemFragment': FolderItemFragment } }
      ) | { __typename: 'VaultImage', id: string } | { __typename: 'VaultTrack', id: string } | { __typename: 'VaultVideo', id: string } }>, pageInfo: { endCursor: string | null, hasNextPage: boolean } } };

export type GetCurrentFolderQueryVariables = Exact<{
  folderId: Scalars['UUID']['input'];
}>;


export type GetCurrentFolderQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, title: string | null, parentVaultContentId: string | null, vaultId: string } | { __typename: 'VaultImage', id: string, title: string | null, parentVaultContentId: string | null, vaultId: string } | { __typename: 'VaultTrack', id: string, title: string | null, parentVaultContentId: string | null, vaultId: string } | { __typename: 'VaultVideo', id: string, title: string | null, parentVaultContentId: string | null, vaultId: string } | null };

export type GetSplitsQueryVariables = Exact<{
  input: QuerySplitDetailsInput;
  artistId: Scalars['UUID']['input'];
}>;


export type GetSplitsQuery = { splitDetails: Array<{ id: string, name: string, email: string, role: string, percentBps: number, isOwnSplit: boolean }> | null };

export type CreateSplitsMutationVariables = Exact<{
  input: MutationAssignSplitsInput;
}>;


export type CreateSplitsMutation = { assignSplits: { __typename: 'MutationAssignSplitsSuccess' } | { __typename: 'NotFoundError', message: string } | { __typename: 'TrackSplitPercentSumError', message: string } | { __typename: 'ValidationError', message: string } };

export type ArtistByHandleQueryVariables = Exact<{
  link: Scalars['String']['input'];
}>;


export type ArtistByHandleQuery = { artistLink: { artist: (
      { id: string, name: string, linkValue: string, mainVaultId: string, mainVault: { id: string, messageChannelId: string, type: VaultType, isUserArtistAdmin: boolean, tiers: Array<{ __typename: 'FreeTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> } | { __typename: 'PaidTier', enabledFeatures: Array<{ feature: { __typename: 'ChatRead' } | { __typename: 'ChatSendAudioAttachments' } | { __typename: 'ChatSendImageAttachments' } | { __typename: 'ChatSendVideoAttachments' } | { __typename: 'ChatWrite' } | { __typename: 'FreeScheduledEvent' } | { __typename: 'FreeVaultContent' } | { __typename: 'PaidScheduledEvent' } | { __typename: 'PaidVaultContent' } | { __typename: 'TrackCommentsRead' } | { __typename: 'TrackCommentsWrite' } | { __typename: 'UnknownFeature' } | { __typename: 'UserReferrals' } }> }>, activeSubscription: (
          { id: string, status: VaultSubscriptionStatus }
          & { ' $fragmentRefs'?: { 'ActiveSubscriptionFeaturesFragment': ActiveSubscriptionFeaturesFragment } }
        ) | null }, profileImage: { id: string, url: string, dominantColor: string | null } | null, phoneNumbers: Array<{ phoneNumber: string }>, membershipCardImage: { id: string, url: string } | null }
      & { ' $fragmentRefs'?: { 'ArtistLayoutFragment': ArtistLayoutFragment;'ArtistMainVaultViewFragment': ArtistMainVaultViewFragment;'ArtistLandingFragment': ArtistLandingFragment } }
    ) } | null };

export type VisualVaultContentQueryVariables = Exact<{
  vaultContentId: Scalars['UUID']['input'];
}>;


export type VisualVaultContentQuery = { vaultContentById: { __typename: 'VaultFolder', id: string, title: string | null, linkValue: string } | { __typename: 'VaultImage', id: string, blurredMediaUrl: string | null, title: string | null, linkValue: string, uploadedMedia: { id: string, url: string, mediaType: MediaType } | null } | { __typename: 'VaultTrack', id: string, title: string | null, linkValue: string } | { __typename: 'VaultVideo', id: string, blurredMediaUrl: string | null, title: string | null, linkValue: string, uploadedMedia: { id: string, url: string, mediaType: MediaType } | null } | null };

export type AnnouncementRowFragment = { id: string, content: string | null, scheduledAt: string, isFullVersionAvailable: boolean, status: ScheduledEventStatus, featureAccess: Array<{ feature: { __typename: 'FreeScheduledEvent' } | { __typename: 'PaidScheduledEvent' } }>, vault: { id: string, artist: { id: string, name: string, profileImage: { id: string, url: string } | null } | null } } & { ' $fragmentName'?: 'AnnouncementRowFragment' };

export type CancelScheduledAnnouncementMutationVariables = Exact<{
  input: MutationDeleteAnnouncementInput;
}>;


export type CancelScheduledAnnouncementMutation = { deleteAnnouncement: { __typename: 'MutationDeleteAnnouncementSuccess' } | { __typename: 'NotFoundError', message: string } };

export type HideScheduledAnnouncementMutationVariables = Exact<{
  input: MutationHideAnnouncementInput;
}>;


export type HideScheduledAnnouncementMutation = { hideAnnouncement: { __typename: 'MutationHideAnnouncementSuccess' } | { __typename: 'NotFoundError', message: string } };

export type ShowScheduledAnnouncementMutationVariables = Exact<{
  input: MutationShowAnnouncementInput;
}>;


export type ShowScheduledAnnouncementMutation = { showAnnouncement: { __typename: 'MutationShowAnnouncementSuccess' } | { __typename: 'NotFoundError', message: string } };

export type ReleaseCampaignByIdOrSlugQueryVariables = Exact<{
  artistHandle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReleaseCampaignByIdOrSlugQuery = { releaseCampaignByIdOrSlug: (
    { id: string, currentState: ReleaseCampaignState }
    & { ' $fragmentRefs'?: { 'MusicCampaignViewFragment': MusicCampaignViewFragment;'ClaimReceiptViewFragment': ClaimReceiptViewFragment } }
  ) | null };

export type ReleaseCampaignByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type ReleaseCampaignByIdQuery = { releaseCampaignByIdOrSlug: { __typename: 'ReleaseCampaign', id: string, appleMusicResourceId: string | null, contentType: ReleaseCampaignContentType, createdAt: string, description: string | null, isrc: string | null, upc: string | null, showAppleMusicPresave: boolean, showSpotifyPresave: boolean, initialReleaseImageUrl: string | null, linkValue: string, currentState: ReleaseCampaignState, releaseDate: string | null, status: ReleaseCampaignStatus, title: string, announcement: { id: string, content: string | null, scheduledAt: string } | null, releaseAnnouncement: { id: string, content: string | null } | null, artist: { id: string, linkValue: string, name: string, profileImage: { id: string, url: string } | null }, coverImage: { id: string, url: string } | null, externalLinks: Array<{ cta: string | null, enabled: boolean, id: string, order: number, platform: ThirdPartyPlatform, releaseCampaignId: string, url: string }> } | null };

export type EventsSummaryQueryVariables = Exact<{
  artistHandle: Scalars['String']['input'];
}>;


export type EventsSummaryQuery = { myEventsSummary: { artistMembershipReceiptsCount: number, newSubscriptionsFromEventsCount: number } };

export type MembershipArtistQueryVariables = Exact<{
  link: Scalars['String']['input'];
}>;


export type MembershipArtistQuery = { artistLink: { artist: { id: string, name: string, linkValue: string, mainVaultId: string, membershipCardImage: { id: string, url: string } | null } } | null };

export type SortVaultContentMutationVariables = Exact<{
  input: MutationSetVaultContentOrderInput;
}>;


export type SortVaultContentMutation = { setVaultContentOrder: { __typename: 'MutationSetVaultContentOrderSuccess', data: boolean } | { __typename: 'NotAuthorizedError' } | { __typename: 'ValidationError' } };

export type CreateMultipartUploadMutationVariables = Exact<{
  input: MutationCreateMultipartUploadInput;
}>;


export type CreateMultipartUploadMutation = { createMultipartUpload: { __typename: 'AwsRequestError', message: string, requestId: string } | { __typename: 'MutationCreateMultipartUploadSuccess', data: { uploadId: string, uploadKey: string, signedUrls: Array<{ partNumber: number, url: string }> } } | { __typename: 'NotAuthorizedError', message: string } };

export type AbortMultipartUploadMutationVariables = Exact<{
  input: MutationAbortMultipartUploadInput;
}>;


export type AbortMultipartUploadMutation = { abortMultipartUpload: { __typename: 'AwsRequestError', message: string, requestId: string } | { __typename: 'MutationAbortMultipartUploadSuccess', data: { __typename: 'AbortMultipartUploadOutput', uploadKey: string } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };

export type CompleteMultipartUploadMutationVariables = Exact<{
  input: MutationCompleteMultipartUploadInput;
}>;


export type CompleteMultipartUploadMutation = { completeMultipartUpload: { __typename: 'AwsRequestError', message: string, requestId: string } | { __typename: 'MutationCompleteMultipartUploadSuccess', data: { mediaId: string, cdnUrl: string } } | { __typename: 'NotAuthorizedError', message: string } | { __typename: 'NotFoundError', message: string } };
